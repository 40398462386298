import React, { useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface CaveBackgroundProps {
  currentOreColor: string;
}

const CaveBackground: React.FC<CaveBackgroundProps> = React.memo(({ currentOreColor = '#fff' }) => {
  const caveElements = useMemo(() => {
    const elements = [];

    // Stalactites
    for (let i = 0; i < 20; i++) {
      const width = 20 + Math.random() * 60;
      const height = 100 + Math.random() * 200;
      elements.push(
        <div
          key={`stalactite-${i}`}
          className="stalactite"
          style={{
            '--x': `${(i * 5) + Math.random() * 5}%`,
            '--width': `${width}px`,
            '--height': `${height}px`,
            '--delay': `${Math.random() * 5}s`
          } as React.CSSProperties}
        />
      );
    }

    // Stalagmites
    for (let i = 0; i < 20; i++) {
      const width = 20 + Math.random() * 60;
      const height = 100 + Math.random() * 200;
      elements.push(
        <div
          key={`stalagmite-${i}`}
          className="stalagmite"
          style={{
            '--x': `${(i * 5) + Math.random() * 5}%`,
            '--width': `${width}px`,
            '--height': `${height}px`,
            '--delay': `${Math.random() * 5}s`
          } as React.CSSProperties}
        />
      );
    }

    // Crystal formations
    const crystalColors = [
      '#4a9eff80', '#ff7f5080', '#50ff8d80', '#c576f680', '#ffd70080',
      '#ff63c380', '#45e3ff80', '#a64dff80'
    ];

    for (let i = 0; i < 15; i++) {
      const size = 30 + Math.random() * 60;
      const cluster = [];
      const baseColor = crystalColors[Math.floor(Math.random() * crystalColors.length)];
      
      // Create crystal cluster
      for (let j = 0; j < 5; j++) {
        const subSize = size * (0.5 + Math.random() * 0.5);
        cluster.push(
          <div
            key={`crystal-${i}-${j}`}
            className="crystal"
            style={{
              '--size': `${subSize}px`,
              '--x': `${Math.random() * 40 - 20}px`,
              '--y': `${Math.random() * 40 - 20}px`,
              '--color': baseColor,
              '--delay': `${Math.random() * 3}s`,
              '--rotate': `${Math.random() * 360}deg`
            } as React.CSSProperties}
          />
        );
      }

      elements.push(
        <div
          key={`cluster-${i}`}
          className="crystal-cluster"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${20 + Math.random() * 60}%`,
          } as React.CSSProperties}
        >
          {cluster}
        </div>
      );
    }

    // Cave cracks with glowing effect
    for (let i = 0; i < 8; i++) {
      elements.push(
        <div
          key={`crack-${i}`}
          className="cave-crack"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${Math.random() * 100}%`,
            '--rotate': `${Math.random() * 360}deg`,
            '--length': `${100 + Math.random() * 200}px`,
            '--delay': `${Math.random() * 5}s`
          } as React.CSSProperties}
        />
      );
    }

    return elements;
  }, []);

  return (
    <div className="cave-background">
      <div className="cave-lighting" />
      <div className="cave-fog" />
      {caveElements}

      <style>{`
        .cave-background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, 
            #090a0f 0%,
            #171a24 30%,
            #12151c 70%,
            #090a0f 100%
          );
          overflow: hidden;
          z-index: -2;
          perspective: 1000px;
        }

        .cave-lighting {
          position: absolute;
          width: 100%;
          height: 100%;
          background: 
            radial-gradient(circle at 20% 20%, rgba(41, 98, 255, 0.03), transparent 40%),
            radial-gradient(circle at 80% 80%, rgba(255, 65, 184, 0.03), transparent 40%),
            radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.01), transparent 60%);
          pointer-events: none;
          mix-blend-mode: screen;
        }

        .cave-fog {
          position: absolute;
          width: 100%;
          height: 100%;
          background: radial-gradient(
            ellipse at center,
            transparent 0%,
            rgba(0, 0, 0, 0.3) 70%,
            rgba(0, 0, 0, 0.5) 100%
          );
          filter: blur(40px);
          pointer-events: none;
        }

        .stalactite {
          position: absolute;
          left: var(--x);
          top: 0;
          width: var(--width);
          height: var(--height);
          transform-origin: top center;
          animation: sway 10s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .stalactite::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, #2a2e3b 0%, #1a1e27 100%);
          clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
          filter: drop-shadow(0 10px 20px rgba(0,0,0,0.5));
        }

        .stalagmite {
          position: absolute;
          left: var(--x);
          bottom: 0;
          width: var(--width);
          height: var(--height);
          transform-origin: bottom center;
          animation: sway 12s ease-in-out infinite reverse;
          animation-delay: var(--delay);
        }

        .stalagmite::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, #2a2e3b 0%, #1a1e27 100%);
          clip-path: polygon(20% 100%, 80% 100%, 100% 0%, 0% 0%);
          filter: drop-shadow(0 -10px 20px rgba(0,0,0,0.5));
        }

        .crystal-cluster {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: 0;
          height: 0;
          transform-style: preserve-3d;
        }

        .crystal {
          position: absolute;
          width: var(--size);
          height: var(--size);
          transform: translate3d(var(--x), var(--y), 0) rotate(var(--rotate));
          animation: crystalGlow 3s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .crystal::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--color);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          filter: drop-shadow(0 0 20px var(--color));
        }

        .cave-crack {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: var(--length);
          height: 2px;
          background: linear-gradient(90deg, 
            transparent 0%,
            rgba(255, 255, 255, 0.1) 50%,
            transparent 100%
          );
          transform: rotate(var(--rotate));
          animation: crackGlow 4s ease-in-out infinite;
          animation-delay: var(--delay);
          filter: blur(1px);
        }

        @keyframes sway {
          0%, 100% { transform: rotate(-1deg); }
          50% { transform: rotate(1deg); }
        }

        @keyframes crystalGlow {
          0%, 100% { 
            opacity: 0.5;
            filter: brightness(1) blur(0px);
          }
          50% { 
            opacity: 0.8;
            filter: brightness(1.5) blur(2px);
          }
        }

        @keyframes crackGlow {
          0%, 100% { 
            opacity: 0.3;
            filter: blur(1px);
          }
          50% { 
            opacity: 0.6;
            filter: blur(2px);
          }
        }
      `}</style>
    </div>
  );
});

CaveBackground.displayName = 'CaveBackground';

export default CaveBackground;
