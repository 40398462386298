export interface Pickaxe {
  id: string;
  name: string;
  rarity: 'Common' | 'Uncommon' | 'Rare' | 'Epic' | 'Legendary' | 'Mythical';
  description: string;
  perks: {
    autominingSpeed?: number;
    rareOreChance?: number;
    multiMineChance?: number;
    criticalChance?: number;
    valueBonus?: number;
  };
  recipe: {
    [oreName: string]: number;
  };
  style: {
    background: string;
    glow: string;
    animation?: string;
  };
  levelRequired: number;
}

export const pickaxes: Pickaxe[] = [
  {
    id: 'copper_pick',
    name: 'Copper Pickaxe',
    rarity: 'Common',
    description: 'A basic but reliable mining tool.',
    perks: {
      autominingSpeed: 10,
      valueBonus: 2
    },
    recipe: {
      'Copper': 5000,
      'Iron': 900
    },
    style: {
      background: 'linear-gradient(135deg, #b87333 0%, #da9f5c 100%)',
      glow: '#b87333'
    },
    levelRequired: 1
  },
  {
    id: 'silver_pick',
    name: 'Silver-Plated Pickaxe',
    rarity: 'Common',
    description: 'A slightly enhanced pickaxe with silver plating for better conductivity.',
    perks: {
      autominingSpeed: 15,
      valueBonus: 5
    },
    recipe: {
      'Silver': 8000,
      'Iron': 3000,
      'Nickel': 1000
    },
    style: {
      background: 'linear-gradient(135deg, #C0C0C0 0%, #E8E8E8 100%)',
      glow: '#C0C0C0'
    },
    levelRequired: 20
  },
  {
    id: 'emerald_pick',
    name: 'Emerald Core Pickaxe',
    rarity: 'Uncommon',
    description: 'Powered by an emerald core that enhances mining efficiency.',
    perks: {
      autominingSpeed: 25,
      rareOreChance: 8
    },
    recipe: {
      'Emerald': 2000,
      'Platinum': 5000,
      'Silver': 10000
    },
    style: {
      background: 'linear-gradient(135deg, #50C878 0%, #2E8B57 100%)',
      glow: '#50C878',
      animation: 'pulse'
    },
    levelRequired: 30
  },
  {
    id: 'ruby_pick',
    name: 'Ruby Infused Pickaxe',
    rarity: 'Uncommon',
    description: 'Infused with ruby energy for enhanced mining.',
    perks: {
      autominingSpeed: 20,
      rareOreChance: 5
    },
    recipe: {
      'Ruby': 5000,
      'Iron': 15000,
      'Gold': 20000
    },
    style: {
      background: 'linear-gradient(135deg, #FF0000 0%, #8B0000 100%)',
      glow: '#FF0000',
      animation: 'pulse'
    },
    levelRequired: 40
  },
  {
    id: 'tanzanite_pick',
    name: 'Tanzanite Thunder Pickaxe',
    rarity: 'Rare',
    description: 'Channels the unique properties of tanzanite for powerful mining strikes.',
    perks: {
      autominingSpeed: 35,
      rareOreChance: 12,
      criticalChance: 5
    },
    recipe: {
      'Tanzanite': 3000,
      'Platinum': 8000,
      'Diamond': 1000
    },
    style: {
      background: 'linear-gradient(135deg, #4B0082 0%, #9400D3 100%)',
      glow: '#4B0082',
      animation: 'sparkle'
    },
    levelRequired: 60
  },
  {
    id: 'diamond_pick',
    name: 'Diamond Edge Pickaxe',
    rarity: 'Rare',
    description: 'Diamond-tipped for superior mining power.',
    perks: {
      autominingSpeed: 30,
      rareOreChance: 10,
      multiMineChance: 5
    },
    recipe: {
      'Diamond': 3000,
      'Platinum': 10000,
      'Gold': 20000
    },
    style: {
      background: 'linear-gradient(135deg, #89CFF0 0%, #4169E1 100%)',
      glow: '#89CFF0',
      animation: 'sparkle'
    },
    levelRequired: 75
  },
  {
    id: 'bismuth_pick',
    name: 'Bismuth Crystal Pickaxe',
    rarity: 'Epic',
    description: 'Crystalline structures within enhance both power and luck.',
    perks: {
      autominingSpeed: 45,
      rareOreChance: 18,
      multiMineChance: 8,
      criticalChance: 8
    },
    recipe: {
      'Bismuth': 5000,
      'Diamond': 2000,
      'Rhodium': 500
    },
    style: {
      background: 'linear-gradient(135deg, #7B68EE 0%, #C71585 100%)',
      glow: '#7B68EE',
      animation: 'crystal-pulse'
    },
    levelRequired: 90
  },
  {
    id: 'void_pick',
    name: 'Void Essence Pickaxe',
    rarity: 'Epic',
    description: 'Pulsing with mysterious void energy.',
    perks: {
      autominingSpeed: 50,
      rareOreChance: 15,
      multiMineChance: 10,
      criticalChance: 10
    },
    recipe: {
      'Osmium': 1000,
      'Diamond': 3000,
      'Rhodium': 1000
    },
    style: {
      background: 'linear-gradient(135deg, #2E0854 0%, #9932CC 100%)',
      glow: '#9932CC',
      animation: 'void'
    },
    levelRequired: 100
  },
  {
    id: 'antimatter_pick',
    name: 'Antimatter Annihilator',
    rarity: 'Legendary',
    description: 'Harnesses the power of antimatter for unprecedented mining capabilities.',
    perks: {
      autominingSpeed: 75,
      rareOreChance: 25,
      multiMineChance: 15,
      criticalChance: 15,
      valueBonus: 1000
    },
    recipe: {
      'Antimatter': 1000,
      'Osmium': 2000,
      'Uranium': 5000
    },
    style: {
      background: 'linear-gradient(135deg, #00FFFF 0%, #000000 50%, #00FFFF 100%)',
      glow: '#00FFFF',
      animation: 'antimatter-pulse'
    },
    levelRequired: 150
  },
  {
    id: 'infinity_pick',
    name: 'Infinity Edge Pickaxe',
    rarity: 'Mythical',
    description: 'Forged from the rarest materials in existence.',
    perks: {
      autominingSpeed: 100,
      rareOreChance: 100,
      multiMineChance: 100,
      criticalChance: 20,
      valueBonus: 10000
    },
    recipe: {
      'Unobtanium': 300,
      'Osmium': 500,
      'Diamond': 9000
    },
    style: {
      background: 'linear-gradient(135deg, #000000 0%, #FF00FF 50%, #00FFFF 100%)',
      glow: '#FF00FF',
      animation: 'infinity'
    },
    levelRequired: 200
  }
];
