import React, { useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface LiteCaveBackgroundProps {
  currentOreColor: string;
}

const LiteCaveBackground: React.FC<LiteCaveBackgroundProps> = React.memo(() => {
  // Create static cave elements only once
  const caveElements = useMemo(() => {
    const elements = [];
    
    // Add minimal rock formations (static, no animations)
    for (let i = 0; i < 6; i++) {
      elements.push(
        <div
          key={`rock-${i}`}
          className="rock-formation"
          style={{
            '--x': `${i * 20}%`,
            '--y': `${Math.random() * 60 + 20}%`,
            '--size': `${150 + Math.random() * 100}px`,
            '--rotation': `${Math.random() * 30 - 15}deg`
          } as React.CSSProperties}
        />
      );
    }

    // Add a few ambient light spots (minimal animation)
    for (let i = 0; i < 3; i++) {
      elements.push(
        <div
          key={`light-${i}`}
          className="ambient-light"
          style={{
            '--x': `${20 + i * 30}%`,
            '--y': `${30 + Math.random() * 40}%`,
            '--delay': `${i * 2}s`
          } as React.CSSProperties}
        />
      );
    }

    return elements;
  }, []);

  return (
    <div className="lite-cave-background">
      <div className="gradient-overlay" />
      {caveElements}

      <style>{`
        .lite-cave-background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #0a0c12;
          overflow: hidden;
          z-index: -2;
        }

        .gradient-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: radial-gradient(
            circle at center,
            rgba(20, 25, 35, 0.7) 0%,
            rgba(10, 12, 18, 0.9) 100%
          );
          pointer-events: none;
        }

        .rock-formation {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: var(--size);
          height: var(--size);
          background: linear-gradient(
            45deg,
            #1a1e26 0%,
            #12151c 100%
          );
          clip-path: polygon(
            50% 0%,
            100% 30%,
            100% 70%,
            50% 100%,
            0% 70%,
            0% 30%
          );
          transform: rotate(var(--rotation));
          opacity: 0.5;
        }

        .ambient-light {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: 150px;
          height: 150px;
          background: radial-gradient(
            circle at center,
            rgba(100, 149, 237, 0.05) 0%,
            transparent 70%
          );
          border-radius: 50%;
          animation: gentlePulse 4s ease-in-out infinite;
          animation-delay: var(--delay);
          pointer-events: none;
          will-change: opacity;
        }

        @keyframes gentlePulse {
          0%, 100% { opacity: 0.3; }
          50% { opacity: 0.5; }
        }
      `}</style>
    </div>
  );
});

LiteCaveBackground.displayName = 'LiteCaveBackground';

export default LiteCaveBackground;
