import React, { useState, useEffect } from 'react';
import { 
  Coins, User, Gauge, Pickaxe, Star, 
  Warehouse, Sparkles, Target,
  TrendingUp, Zap, Package, Archive,
  LucideIcon, ChevronUp, ChevronDown
} from "lucide-react";
import { Player } from "./Player";
import { getRankInfo } from './RankConfig';
import { NumberFormatter } from './NumberFormatter';

interface StatsProps {
  player?: Player;
}

interface StatCardProps {
  icon: LucideIcon;
  label: string;
  value: string | number;
  subValue?: string;
  color: string;
  glowColor?: string;
  onClick?: () => void;
  clickable?: boolean;
}

const StatCard: React.FC<StatCardProps> = ({ 
  icon: Icon, 
  label, 
  value, 
  subValue, 
  color, 
  glowColor,
  onClick,
  clickable
}) => (
  <div 
    onClick={onClick}
    style={{ 
      color: '#fff',
      background: `linear-gradient(135deg, rgba(40,40,40,0.8) 0%, rgba(20,20,20,0.8) 100%)`,
      borderRadius: '8px',
      marginBottom: '8px',
      border: '1px solid rgba(255,255,255,0.05)',
      boxShadow: `0 2px 10px rgba(0,0,0,0.2), inset 0 0 20px ${glowColor || color}15`,
      transition: 'all 0.2s ease',
      cursor: clickable ? 'pointer' : 'default'
    }} 
    className={`d-flex align-items-center p-2 stat-card ${clickable ? 'clickable-stat' : ''}`}
  >
    <div style={{ 
      backgroundColor: `${color}20`,
      padding: '8px',
      borderRadius: '6px',
      marginRight: '10px',
      boxShadow: `0 0 10px ${color}30`
    }}>
      <Icon size={16} style={{ 
        color,
        filter: `drop-shadow(0 0 2px ${color}80)`
      }} />
    </div>
    <div>
      <div style={{ 
        fontSize: '0.7rem', 
        opacity: 0.8,
        letterSpacing: '0.05em',
        textShadow: '0 0 5px rgba(0,0,0,0.5)'
      }}>{label}</div>
      <div style={{ fontSize: '0.9rem' }} className="d-flex align-items-center">
        <span style={{ 
          fontWeight: 'bold',
          textShadow: `0 0 10px ${color}50`,
          color: value === 'Enabled' ? '#2ecc71' : value === 'Disabled' ? '#e74c3c' : '#fff'
        }}>{value}</span>
        {subValue && (
          <span style={{ 
            fontSize: '0.7rem', 
            opacity: 0.7, 
            marginLeft: '4px',
            color: color
          }}>
            ({subValue})
          </span>
        )}
      </div>
    </div>
  </div>
);

const Stats: React.FC<StatsProps> = ({ player }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [, setTick] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((t: number) => t + 1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  if (!player) return null;

  const playerLevel = player.getLevel();
  const { rank, color } = getRankInfo(playerLevel);
  const progress = player.getLevelProgress();
  const inventory = player.getInventorySummary();
  const totalCollection = player.getTotalCollectionSummary();
  
  const currentOres = inventory.reduce((acc, { quantity }) => acc + quantity, 0);
  const totalOres = totalCollection.reduce((acc, { quantity }) => acc + quantity, 0);

  const toggleAutoSell = () => {
    player.hasAutoSell = !player.hasAutoSell;
    setTick(t => t + 1);
  };

  return (
    <div style={{ 
      color: '#fff',
      width: '250px',
      background: 'linear-gradient(135deg, rgba(25,25,25,0.95) 0%, rgba(15,15,15,0.95) 100%)',
      borderRadius: '12px',
      border: '1px solid rgba(255,255,255,0.05)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
      transition: 'all 0.3s ease',
      overflow: 'hidden'
    }}>
      <div 
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          padding: '12px',
          cursor: 'pointer',
          transition: 'background 0.2s ease',
          userSelect: 'none'
        }}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex align-items-center gap-3">
          <div style={{ 
            backgroundColor: 'rgba(255,215,0,0.1)',
            padding: '8px',
            borderRadius: '6px',
            boxShadow: '0 0 10px rgba(255,215,0,0.2)'
          }}>
            <Star size={16} style={{ 
              color: '#ffd700',
              filter: 'drop-shadow(0 0 2px rgba(255,215,0,0.5))'
            }} />
          </div>
          <div>
            <div style={{ 
              background: 'linear-gradient(45deg, #ffd700, #ffa500)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontSize: '0.9rem',
              fontWeight: 'bold'
            }}>Level {playerLevel}</div>
            <div style={{ fontSize: '0.7rem', opacity: 0.6 }}>
              {NumberFormatter.format(currentOres)} ores
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span style={{ 
            fontSize: '0.8rem', 
            background: `linear-gradient(135deg, ${color}, ${color}80)`,
            padding: '3px 10px',
            borderRadius: '12px',
            color: '#fff',
            fontWeight: 'bold',
            boxShadow: `0 0 15px ${color}40`,
            textShadow: `0 0 5px ${color}`,
          }}>
            {rank}
          </span>
          {isExpanded ? (
            <ChevronUp size={20} className="text-muted" />
          ) : (
            <ChevronDown size={20} className="text-muted" />
          )}
        </div>
      </div>

      <div style={{
        maxHeight: isExpanded ? '1000px' : '0',
        opacity: isExpanded ? 1 : 0,
        transition: 'all 0.3s ease',
        padding: isExpanded ? '0 12px 12px 12px' : '0 12px',
        pointerEvents: isExpanded ? 'auto' : 'none'
      }}>
        <div className="progress mb-3" style={{ 
          height: '6px', 
          backgroundColor: 'rgba(0,0,0,0.3)',
          borderRadius: '3px',
          padding: '1px'
        }}>
          <div className="progress-bar" style={{ 
            width: `${progress.percentage}%`,
            background: 'linear-gradient(90deg, #ffd700, #ffa500)',
            boxShadow: '0 0 10px rgba(255,215,0,0.5)',
            borderRadius: '3px',
            transition: 'width 0.3s ease'
          }} />
        </div>

        <StatCard 
          icon={User}
          label="PLAYER"
          value={player.username}
          color="#4a9eff"
        />

        <StatCard 
          icon={Coins}
          label="NET WORTH"
          value={NumberFormatter.formatCurrency(player.netWorth)}
          color="#ffd700"
        />

        <StatCard 
          icon={Package}
          label="CURRENT ORES"
          value={NumberFormatter.format(currentOres)}
          subValue={player.hasAutoSell ? "Auto Sell On" : undefined}
          color="#c0c0c0"
        />

        <StatCard 
          icon={Archive}
          label="TOTAL COLLECTED"
          value={NumberFormatter.format(totalOres)}
          color="#a0a0a0"
        />

        <StatCard 
          icon={Pickaxe}
          label="AUTO MINERS"
          value={NumberFormatter.format(player.autoMiners)}
          subValue={`+${(player.autoMiners * player.autoMinerSpeed).toFixed(1)}/s`}
          color="#ff7f50"
        />

        <StatCard 
          icon={Warehouse}
          label="QUARRIES"
          value={player.quarries.toLocaleString()}
          subValue={`+${NumberFormatter.format(player.quarries * player.quarrySpeed)}/s`}
          color="#ff9f50"
        />

        <StatCard 
          icon={Gauge}
          label="MINING SPEED"
          value={NumberFormatter.formatRate(player.totalMiningSpeed)}
          color="#50ff8d"
        />

        <StatCard 
          icon={Sparkles}
          label="MINING LUCK"
          value={`+${(player.miningLuck * 5).toFixed(1)}%`}
          subValue="Rare ore chance"
          color="#c576f6"
        />

        <StatCard 
          icon={Target}
          label="MULTI-MINE"
          value={`+${(player.multiMineLevel * 10).toFixed(1)}%`}
          subValue="Double mining chance"
          color="#5d9cec"
        />

        <StatCard 
          icon={TrendingUp}
          label="VALUE BONUS"
          value={`+${((player.valueMultiplier - 1) * 100).toFixed(1)}%`}
          color="#2ecc71"
        />

        <StatCard 
          icon={Zap}
          label="AUTO-SELL (Click to toggle)"
          value={player.hasAutoSell ? "Enabled" : "Disabled"}
          color={player.hasAutoSell ? "#2ecc71" : "#e74c3c"}
          onClick={toggleAutoSell}
          clickable={true}
        />
      </div>

      <style>{`
        @keyframes pulse {
          0% { opacity: 0.8; }
          50% { opacity: 1; }
          100% { opacity: 0.8; }
        }
        
        .stat-card:hover {
          transform: translateX(4px);
          filter: brightness(1.1);
        }

        .clickable-stat {
          position: relative;
          overflow: hidden;
        }

        .clickable-stat:hover::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          pointer-events: none;
        }

        .clickable-stat:active {
          transform: scale(0.98);
        }
      `}</style>
    </div>
  );
};

export default Stats;
