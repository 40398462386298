import { db } from './firebaseConfig';
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp
} from 'firebase/firestore';
import { Player } from './Player';
import { Ore } from './Ore';

interface PlayerData {
  username: string;
  stats: {
    autoMiners: number;
    autoMinerSpeed: number;
    quarries: number;
    quarrySpeed: number;
    miningLuck: number;
    valueMultiplier: number;
    multiMineLevel: number;
  };
  inventory: {
    [key: string]: {
      ore: {
        name: string;
        value: number;
        rarity: number;
        description: string;
        color: string;
      };
      quantity: number;
    };
  };
  totalCollection: {
    [key: string]: {
      ore: {
        name: string;
        value: number;
        rarity: number;
        description: string;
        color: string;
      };
      quantity: number;
    };
  };
  currentPickaxeId: string | null;
  lastUpdated: any;
}

export const savePlayerData = async (userId: string, player: Player) => {
  try {
    const playerRef = doc(db, 'players', userId);

    // Helper function to serialize Ore objects
    const serializeOre = (ore: Ore) => ({
      name: ore.name,
      value: ore.value,
      rarity: ore.rarity,
      description: ore.description,
      color: ore.color
    });

    // Serialize inventory
    const serializedInventory: { [key: string]: any } = {};
    const inventory = player.getInventory();
    Object.keys(inventory).forEach(key => {
      serializedInventory[key] = {
        ore: serializeOre(inventory[key].ore),
        quantity: inventory[key].quantity
      };
    });

    // Serialize total collection
    const serializedTotalCollection: { [key: string]: any } = {};
    const totalCollection = player.getTotalCollection();
    Object.keys(totalCollection).forEach(key => {
      serializedTotalCollection[key] = {
        ore: serializeOre(totalCollection[key].ore),
        quantity: totalCollection[key].quantity
      };
    });

    const playerData = {
      username: player.name,
      stats: {
        autoMiners: player.autoMiners,
        autoMinerSpeed: player.autoMinerSpeed,
        quarries: player.quarries,
        quarrySpeed: player.quarrySpeed,
        miningLuck: player.miningLuck,
        valueMultiplier: player.valueMultiplier,
        multiMineLevel: player.multiMineLevel
      },
      inventory: serializedInventory,
      totalCollection: serializedTotalCollection,
      currentPickaxeId: null,
      lastUpdated: serverTimestamp()
    };

    await setDoc(playerRef, playerData, {merge: true});
  } catch (error) {
    console.error('Save error:', error);
    throw error;
  }
};

export const loadPlayerData = async (userId: string) => {
  try {
    const playerRef = doc(db, 'players', userId);
    const playerDoc = await getDoc(playerRef);

    if (playerDoc.exists()) {
      return playerDoc.data();
    } else {
      // Create initial player data
      const initialData = {
        username: 'New Player',
        stats: {
          autoMiners: 0,
          autoMinerSpeed: 1,
          quarries: 0,
          quarrySpeed: 500,
          miningLuck: 0,
          valueMultiplier: 1,
          multiMineLevel: 0
        },
        inventory: {},
        totalCollection: {},
        currentPickaxeId: null,
        lastUpdated: serverTimestamp()
      };

      await setDoc(playerRef, initialData);
      return initialData;
    }
  } catch (error) {
    console.error('Load error:', error);
    throw error;
  }
};

export const initializePlayerFromData = (player: Player, data: PlayerData) => {
  // Set basic stats
  player.autoMiners = data.stats.autoMiners || 0;
  player.autoMinerSpeed = data.stats.autoMinerSpeed || 1;
  player.quarries = data.stats.quarries || 0;
  player.quarrySpeed = data.stats.quarrySpeed || 500;
  player.miningLuck = data.stats.miningLuck || 0;
  player.valueMultiplier = data.stats.valueMultiplier || 1;
  player.multiMineLevel = data.stats.multiMineLevel || 0;

  // Clear existing inventory and total collection
  player.clearInventory();

  // Load inventory
  if (data.inventory) {
    Object.entries(data.inventory).forEach(([oreName, data]: [string, any]) => {
      if (data.ore && data.quantity) {
        const ore = new Ore(
          data.ore.name,
          data.ore.rarity,
          data.ore.description,
          data.ore.value,
          data.ore.color
        );
        player.setInventoryOre(ore, data.quantity);
      }
    });
  }

  // Load total collection
  if (data.totalCollection) {
    Object.entries(data.totalCollection).forEach(([oreName, data]: [string, any]) => {
      if (data.ore && data.quantity) {
        const ore = new Ore(
          data.ore.name,
          data.ore.rarity,
          data.ore.description,
          data.ore.value,
          data.ore.color
        );
        player.setTotalCollectionOre(ore, data.quantity);
      }
    });
  }

  return player;
};
