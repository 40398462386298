class Ore {
  name: string;
  rarity: number;
  description: string;
  value: number;
  color: string;


  constructor(name: string, rarity: number, description: string, value: number, color: string) {
    this.name = name;
    this.rarity = rarity;
    this.description = description;
    this.value = value;
    this.color = color;
  }

}

export { Ore };
