import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, query, getDocs } from 'firebase/firestore';
import { NumberFormatter } from './NumberFormatter';
import { Trophy, Crown, Medal, Star } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface PlayerRanking {
  username: string;
  totalOres: number;
  rank: number;
}

interface LeaderboardProps {
  isOpen: boolean;
  onClose: () => void;
}

const GlobalLeaderboard: React.FC<LeaderboardProps> = ({ isOpen, onClose }) => {
  const [rankings, setRankings] = useState<PlayerRanking[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch data whenever the leaderboard is opened
  useEffect(() => {
    if (isOpen) {
      fetchLeaderboard();
    }
  }, [isOpen]);

  const calculateTotalOres = (playerData: any): number => {
    let total = 0;
    if (playerData.totalCollection) {
      Object.values(playerData.totalCollection).forEach((item: any) => {
        if (item.quantity) total += item.quantity;
      });
    }
    return total;
  };

  const fetchLeaderboard = async (): Promise<void> => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(query(collection(db, 'players')));
      const playerRankings: PlayerRanking[] = [];

      querySnapshot.forEach((doc) => {
        const playerData = doc.data();
        if (playerData.username) {
          playerRankings.push({
            username: playerData.username,
            totalOres: calculateTotalOres(playerData),
            rank: 0
          });
        }
      });

      playerRankings.sort((a, b) => b.totalOres - a.totalOres);
      playerRankings.forEach((player, index) => {
        player.rank = index + 1;
      });

      setRankings(playerRankings);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const getRankIcon = (rank: number): JSX.Element => {
    switch(rank) {
      case 1:
        return <Crown size={24} className="rank-icon" />;
      case 2:
        return <Medal size={24} className="rank-icon" />;
      case 3:
        return <Medal size={24} className="rank-icon" />;
      default:
        return <Star size={20} className="rank-icon" />;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="leaderboard-overlay">
      <div className="leaderboard-modal">
        <div className="d-flex align-items-center justify-content-between mb-4 pb-3 border-bottom">
          <div className="d-flex align-items-center gap-3">
            <Trophy size={32} color="#FFD700" />
            <h2 className="m-0 gradient-text">Total Ores Collected</h2>
          </div>
          <button className="btn-close btn-close-white" onClick={onClose}></button>
        </div>

        {loading ? (
          <div className="text-center p-5">
            <div className="spinner-grow text-primary me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-primary me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : rankings.length > 0 ? (
          <div className="rankings-list">
            {rankings.map((player, index) => (
              <div key={player.username} className={`ranking-item rank-${player.rank}`}>
                <div className="d-flex align-items-center">
                  <div className="rank-number">
                    {getRankIcon(player.rank)}
                    <span>#{player.rank}</span>
                  </div>
                  <div className="player-info">
                    <div className="player-name">{player.username}</div>
                    <div className="player-worth">{NumberFormatter.format(player.totalOres)} ores</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-5">
            <Trophy size={48} className="mb-3 opacity-50" />
            <p className="text-muted">No rankings available</p>
          </div>
        )}
      </div>

      <style>
        {`
          .leaderboard-overlay {
            position: fixed;
            inset: 0;
            background: rgba(0, 0, 0, 0.9);
            backdrop-filter: blur(8px);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1050;
            animation: fadeIn 0.3s ease;
          }

          .leaderboard-modal {
            background: linear-gradient(165deg, rgba(30, 34, 38, 0.95), rgba(20, 24, 28, 0.95));
            border-radius: 20px;
            padding: 2rem;
            width: 90%;
            max-width: 600px;
            max-height: 85vh;
            overflow-y: auto;
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
            animation: slideUp 0.4s ease;
          }

          .gradient-text {
            background: linear-gradient(45deg, #fff, #ffd700);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 2rem;
            font-weight: bold;
          }

          .rankings-list {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
          }

          .ranking-item {
            background: rgba(255, 255, 255, 0.05);
            padding: 1rem 1.5rem;
            border-radius: 12px;
            transition: all 0.3s ease;
            animation: slideIn 0.5s ease forwards;
          }

          .ranking-item:hover {
            transform: translateX(10px);
            background: rgba(255, 255, 255, 0.1);
          }

          .rank-1 {
            background: linear-gradient(135deg, rgba(255, 215, 0, 0.15), rgba(255, 215, 0, 0.05));
            border: 1px solid rgba(255, 215, 0, 0.3);
          }

          .rank-2 {
            background: linear-gradient(135deg, rgba(192, 192, 192, 0.15), rgba(192, 192, 192, 0.05));
            border: 1px solid rgba(192, 192, 192, 0.3);
          }

          .rank-3 {
            background: linear-gradient(135deg, rgba(205, 127, 50, 0.15), rgba(205, 127, 50, 0.05));
            border: 1px solid rgba(205, 127, 50, 0.3);
          }

          .rank-number {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            min-width: 80px;
            color: white;
            font-weight: bold;
          }

          .rank-1 .rank-number { color: #FFD700; }
          .rank-2 .rank-number { color: #C0C0C0; }
          .rank-3 .rank-number { color: #CD7F32; }

          .player-info {
            flex-grow: 1;
            margin-left: 1rem;
          }

          .player-name {
            color: white;
            font-weight: 500;
            font-size: 1.1rem;
          }

          .player-worth {
            color: #4a90e2;
            font-size: 0.9rem;
          }

          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes slideUp {
            from { transform: translateY(30px); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
          }

          @keyframes slideIn {
            from { transform: translateX(-20px); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default GlobalLeaderboard;
