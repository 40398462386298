import React, { useState, useEffect } from 'react';
import { Trophy, X, ChevronUp } from 'lucide-react';
import { Player } from './Player';
import { RankInfo, ranks, getRankInfo} from './RankConfig'

interface RanksProps {
  player: Player;
}



const RankList: React.FC<RanksProps> = ({ player }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setTick] = useState(0);

  // Force update every 100ms to keep progress bars updated
  useEffect(() => {
    const interval = setInterval(() => {
      setTick(tick => tick + 1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const calculateProgress = (currentLevel: number, requiredLevel: number, nextLevelRequired: number = Infinity) => {
    if (currentLevel >= nextLevelRequired) return 100;
    if (currentLevel < requiredLevel) return 0;

    const progress = ((currentLevel - requiredLevel) / (nextLevelRequired - requiredLevel)) * 100;
    return Math.min(100, Math.max(0, progress));
  };

  const getCurrentRankIndex = () => {
    const level = player.getLevel();
    for (let i = ranks.length - 1; i >= 0; i--) {
      if (level >= ranks[i].levelRequired) {
        return i;
      }
    }
    return 0;
  };

  const currentRankIndex = getCurrentRankIndex();

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="legend-button d-flex align-items-center"
      >
        <Trophy size={20} />
        <span className="ms-2">Ranks</span>
      </button>

      {isOpen && (
        <div className="modal-overlay d-flex align-items-center justify-content-center">
          <div className="legend-modal">
            <button onClick={() => setIsOpen(false)} className="close-button">
              <X size={24} />
            </button>

            <div className="text-center mb-4 pb-3 border-bottom border-secondary">
              <h2 className="legend-title">Rank Progression</h2>
            </div>

            <div className="container-fluid p-3">
              <div className="row g-4">
                {ranks.map((rank, index) => {
                  const nextLevel = ranks[index + 1]?.levelRequired;
                  const progress = calculateProgress(player.getLevel(), rank.levelRequired, nextLevel);
                  const isCurrentRank = index === currentRankIndex;
                  const isAchieved = player.getLevel() >= rank.levelRequired;

                  return (
                    <div key={rank.rank} className="col-12">
                      <div className="rank-card position-relative" style={{
                        background: isCurrentRank ? `linear-gradient(90deg, rgba(${rank.color}, 0.1), rgba(0,0,0,0))` : 'rgba(255, 255, 255, 0.05)',
                        borderRadius: '12px',
                        padding: '1.5rem',
                        border: `1px solid ${isCurrentRank ? rank.color : 'rgba(255, 255, 255, 0.1)'}`,
                        transition: 'all 0.3s ease'
                      }}>
                        {isCurrentRank && (
                          <div className="position-absolute" style={{ right: '1rem', top: '50%', transform: 'translateY(-50%)' }}>
                            <ChevronUp size={24} className="text-white animate-bounce" />
                          </div>
                        )}

                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h3 className={`rank-name ${rank.glow ? 'rank-glow' : ''}`}
                              style={{
                                color: rank.color,
                                fontSize: '1.2rem',
                                margin: 0,
                                textShadow: rank.glow ? `0 0 10px ${rank.color}` : 'none'
                              }}>
                            {rank.rank}
                          </h3>
                          <span className="threshold-value" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          </span>
                        </div>

                        <div className="progress" style={{ height: '8px', background: 'rgba(0,0,0,0.3)' }}>
                          <div
                            className="progress-bar"
                            style={{
                              width: `${progress}%`,
                              backgroundColor: rank.color,
                              boxShadow: `0 0 10px ${rank.color}`,
                              transition: 'width 0.3s ease'
                            }}
                          />
                        </div>

                        {isCurrentRank && nextLevel && (
                          <div className="mt-2 text-end">
                            <small style={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                            </small>
                          </div>
                        )}

                        {isAchieved && (
                          <div className="position-absolute" style={{ right: '1rem', top: '1rem' }}>
                            <Trophy size={16} style={{ color: rank.color }} />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          .rank-glow {
            animation: rankGlow 2s ease-in-out infinite;
          }

          @keyframes rankGlow {
            0%, 100% {
              filter: drop-shadow(0 0 2px currentColor) drop-shadow(0 0 4px currentColor);
            }
            50% {
              filter: drop-shadow(0 0 4px currentColor) drop-shadow(0 0 8px currentColor);
            }
          }

          @keyframes bounce {
            0%, 100% { transform: translateY(-50%) translateX(-25%); }
            50% { transform: translateY(-25%) translateX(-25%); }
          }

          .animate-bounce {
            animation: bounce 1s ease-in-out infinite;
          }
        `}
      </style>
    </>
  );
};

export default RankList;
