import React, { useState, useEffect } from 'react';
import { Player } from './Player';
import { 
  Pickaxe, 
  Gauge, 
  ShoppingCart, 
  X, 
  Zap, 
  Target, 
  Gem, 
  Warehouse, 
  Coins 
} from 'lucide-react';
import './Shop.css';
import { NumberFormatter } from './NumberFormatter';

interface ShopProps {
  player: Player;
}

interface ShopItem {
  id: string;
  title: string;
  icon: React.ReactNode;
  iconColor: string;
  current: number;
  currentSuffix: string;
  description: string;
  cost: number;
  levelRequired?: number;
  action: () => void;
}

const Shop: React.FC<ShopProps> = ({ player }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setTick] = useState(0);
  const [showInsufficientFunds, setShowInsufficientFunds] = useState(false);

  // Update display
  useEffect(() => {
    const interval = setInterval(() => {
      setTick(tick => tick + 1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  // Cost calculations
  const getAutoMinerCost = () => Math.floor(500 * Math.pow(3, player.autoMiners));
  const getSpeedUpgradeCost = () => Math.floor(2000 * Math.pow(200, player.autoMinerSpeed - 0.5));
  const getLuckUpgradeCost = () => Math.floor(10000 * Math.pow(2, player.miningLuck || 0));
  const getValueMultiplierCost = () => Math.floor(25000 * Math.pow(3, player.valueMultiplier - 1 || 0));
  const getMultiMineCost = () => Math.floor(50000 * Math.pow(2, player.multiMineLevel || 0));
  const getQuarryCost = () => Math.floor(1000000 * Math.pow(4, player.quarries));
  const getQuarrySpeedCost = () => Math.floor(500000 * Math.pow(4, (player.quarrySpeed - 500) / 500));

  // Quarry level requirement calculations
  const BASE_QUARRY_LEVEL = 50;
  const getQuarryLevelRequirement = (currentQuarries: number): number => {
    if (currentQuarries === 0) return BASE_QUARRY_LEVEL;
    // Each purchase increases the level requirement exponentially
    return BASE_QUARRY_LEVEL + (40 * currentQuarries) + (10 * Math.pow(currentQuarries - 1, 2));
  };

  const getQuarryDescription = (currentQuarries: number): string => {
    const nextLevel = getQuarryLevelRequirement(currentQuarries + 1);
    if (currentQuarries === 0) {
      return `A massive mining operation that mines large quantities of ores per second (Requires Level ${BASE_QUARRY_LEVEL})`;
    }
    return `A massive mining operation that mines large quantities of ores per second (Next quarry requires Level ${nextLevel})`;
  };

  // Helper functions
  const handlePurchaseAttempt = (cost: number, action: () => void) => {
    if (player.getMoney() >= cost) {
      if (player.spendMoney(cost)) {
        action();
        setShowInsufficientFunds(false);
      }
    } else {
      setShowInsufficientFunds(true);
      setTimeout(() => setShowInsufficientFunds(false), 2000);
    }
  };

  const isItemDisabled = (item: ShopItem): boolean => {
    if (player.getMoney() < item.cost) return true;
    if (item.id === 'quarry') {
      const requiredLevel = getQuarryLevelRequirement(player.quarries);
      return player.getLevel() < requiredLevel;
    }
    if (item.levelRequired && player.getLevel() < item.levelRequired) return true;
    return false;
  };

  const getButtonClass = (item: ShopItem): string => {
    return `btn w-100 ${isItemDisabled(item) ? 'btn-disabled' : 'btn-purchase'}`;
  };

  // Define shop items
  const shopItems: ShopItem[] = [
    {
      id: 'autominer',
      title: 'Auto Miner',
      icon: <Pickaxe size={24} />,
      iconColor: '#3b82f6',
      current: player.autoMiners,
      currentSuffix: '',
      description: 'Automatically mines ore for you every second',
      cost: getAutoMinerCost(),
      action: () => {
        const cost = getAutoMinerCost();
        handlePurchaseAttempt(cost, () => {
          player.autoMiners += 1;
        });
      }
    },
    {
      id: 'speed',
      title: 'Mining Speed',
      icon: <Gauge size={24} />,
      iconColor: '#10b981',
      current: player.autoMinerSpeed,
      currentSuffix: 'x',
      description: 'Increases the speed of your auto miners',
      cost: getSpeedUpgradeCost(),
      action: () => {
        const cost = getSpeedUpgradeCost();
        handlePurchaseAttempt(cost, () => {
          player.autoMinerSpeed += 0.1;
        });
      }
    },
    {
      id: 'luck',
      title: 'Mining Luck',
      icon: <Target size={24} />,
      iconColor: '#f59e0b',
      current: player.miningLuck || 0,
      currentSuffix: '',
      description: '+5% chance to find rare ores per level',
      cost: getLuckUpgradeCost(),
      action: () => {
        const cost = getLuckUpgradeCost();
        handlePurchaseAttempt(cost, () => {
          player.miningLuck = (player.miningLuck || 0) + 1;
        });
      }
    },
    {
      id: 'value',
      title: 'Value Multiplier',
      icon: <Gem size={24} />,
      iconColor: '#8b5cf6',
      current: ((player.valueMultiplier || 1) * 100),
      currentSuffix: '%',
      description: 'Increases the value of all mined ores by 20%',
      cost: getValueMultiplierCost(),
      action: () => {
        const cost = getValueMultiplierCost();
        handlePurchaseAttempt(cost, () => {
          player.valueMultiplier = (player.valueMultiplier || 1) + 0.2;
        });
      }
    },
    {
      id: 'multimine',
      title: 'Multi Mine',
      icon: <Zap size={24} />,
      iconColor: '#ec4899',
      current: player.multiMineLevel || 0,
      currentSuffix: '',
      description: '10% chance to mine multiple ores at once',
      cost: getMultiMineCost(),
      action: () => {
        const cost = getMultiMineCost();
        handlePurchaseAttempt(cost, () => {
          player.multiMineLevel = (player.multiMineLevel || 0) + 1;
        });
      }
    },
    {
      id: 'quarry',
      title: 'Quarry',
      icon: <Warehouse size={24} />,
      iconColor: '#ff9f50',
      current: player.quarries,
      currentSuffix: '',
      description: getQuarryDescription(player.quarries),
      cost: getQuarryCost(),
      levelRequired: getQuarryLevelRequirement(player.quarries),
      action: () => {
        const requiredLevel = getQuarryLevelRequirement(player.quarries);
        if (player.getLevel() < requiredLevel) {
          alert(`Requires level ${requiredLevel}`);
          return;
        }
        const cost = getQuarryCost();
        handlePurchaseAttempt(cost, () => {
          player.quarries += 1;
        });
      }
    },
    {
      id: 'quarryspeed',
      title: 'Quarry Speed',
      icon: <Gauge size={24} />,
      iconColor: '#ff9f50',
      current: player.quarrySpeed,
      currentSuffix: '/s',
      description: 'Increases quarry mining speed by 500 ores per second',
      cost: getQuarrySpeedCost(),
      levelRequired: BASE_QUARRY_LEVEL,
      action: () => {
        if (player.getLevel() < BASE_QUARRY_LEVEL) {
          alert(`Requires level ${BASE_QUARRY_LEVEL}`);
          return;
        }
        const cost = getQuarrySpeedCost();
        handlePurchaseAttempt(cost, () => {
          player.quarrySpeed += 500;
        });
      }
    }
  ];

  // Handle selling all ores
  const handleSellAll = () => {
    const value = player.sellAllOres();
    if (value > 0) {
      // You could add a notification here if you want
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="shop-button d-flex align-items-center"
      >
        <ShoppingCart size={20} />
        <span className="ms-2">Shop</span>
      </button>

      {isOpen && (
        <div className="modal-overlay d-flex align-items-center justify-content-center">
          <div className="shop-modal" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <button onClick={() => setIsOpen(false)} className="close-button">
              <X size={24} />
            </button>

            <div className="text-center mb-3">
              <h2 className="shop-title">Upgrade Shop</h2>
              <div className="d-flex justify-content-between align-items-center px-3">
                <p className="text-white opacity-75 mb-0">
                  Money: {NumberFormatter.formatCurrency(player.getMoney())}
                </p>
                <button 
                  onClick={handleSellAll}
                  className="btn btn-outline-primary btn-sm d-flex align-items-center gap-2"
                >
                  <Coins size={16} />
                  Sell All Ores
                </button>
              </div>
            </div>

            {showInsufficientFunds && (
              <div className="alert alert-danger mx-3 mb-3">
                Insufficient funds! Try selling some ores.
              </div>
            )}

            <div className="container-fluid p-2">
              <div className="row g-3">
                {shopItems.map(item => (
                  <div key={item.id} className="col-md-6">
                    <div className="shop-card">
                      <div className="d-flex align-items-center mb-2">
                        <div className="icon-container" style={{ backgroundColor: item.iconColor + '20', color: item.iconColor }}>
                          {item.icon}
                        </div>
                        <div className="ms-3">
                          <h3 className="card-title text-white mb-0" style={{ fontSize: '1.1rem' }}>{item.title}</h3>
                          <p className="card-subtitle mb-0 text-white opacity-75" style={{ fontSize: '0.9rem' }}>
                            Currently: {item.current.toFixed(item.id === 'speed' ? 1 : 0)}{item.currentSuffix}
                          </p>
                        </div>
                      </div>

                      <p className="card-description mb-2 text-white opacity-50" style={{ fontSize: '0.85rem' }}>
                        {item.description}
                      </p>

                      <button
                        onClick={item.action}
                        disabled={isItemDisabled(item)}
                        className={getButtonClass(item)}
                        style={{ padding: '0.5rem' }}
                      >
                        Upgrade for {NumberFormatter.formatCurrency(item.cost)}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Shop;
