import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsOfUse: React.FC<TermsModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="terms-overlay">
      <div className="terms-modal">
        <div className="d-flex align-items-center justify-content-between mb-4 pb-3 border-bottom">
          <div>
            <h2 className="m-0 gradient-text">Terms of Use Agreement</h2>
            <p className="text-muted small mt-2">Last Updated: October 30, 2024</p>
          </div>
          <button className="btn-close btn-close-white" onClick={onClose}></button>
        </div>

        <div className="terms-content">
          <h4>1. Agreement to Terms</h4>
          <p>These Terms of Use constitute a legally binding agreement between you and MineIt ("we," "us," or "our"), governing your access to and use of the MineIt game. By accessing or using MineIt, you confirm that you have read, understood, and agree to be bound by these Terms of Use.</p>

          <h4>2. Intellectual Property Rights</h4>
          <p>MineIt and all its content, features, and functionality are owned by Justin Jaques and are protected by international copyright, trademark, and other intellectual property laws. Any unauthorized use of the game or its content is strictly prohibited.</p>

          <h4>3. User Accounts</h4>
          <p>You must be at least 13 years of age to create an account. You are responsible for:</p>
          <ul>
            <li>Maintaining account security and confidentiality</li>
            <li>All activities occurring under your account</li>
            <li>Immediately notifying us of any unauthorized access</li>
            <li>Ensuring your account information remains accurate</li>
          </ul>

          <h4>4. Virtual Currency and Items</h4>
          <p>All in-game currency, items, and resources:</p>
          <ul>
            <li>Have no real-world monetary value</li>
            <li>Cannot be exchanged for real money</li>
            <li>Are non-transferable between accounts</li>
            <li>May be modified or removed at our discretion</li>
            <li>Remain our exclusive property</li>
          </ul>

          <h4>5. Prohibited Activities</h4>
          <p>Users are strictly prohibited from:</p>
          <ul>
            <li>Using automated systems, bots, or scripts</li>
            <li>Exploiting bugs or technical glitches</li>
            <li>Engaging in any form of harassment or abuse</li>
            <li>Attempting to decompile or reverse engineer the game</li>
            <li>Using the game for commercial purposes without authorization</li>
          </ul>

          <h4>6. Termination Rights</h4>
          <p>We reserve the right to:</p>
          <ul>
            <li>Suspend or terminate accounts for Terms violations</li>
            <li>Modify or discontinue the game without notice</li>
            <li>Remove virtual items or currency at our discretion</li>
            <li>Take action against suspected fraudulent activity</li>
          </ul>

          <h4>7. Disclaimers and Limitations</h4>
          <p>THE GAME IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING FROM YOUR USE OF THE GAME.</p>

          <h4>8. Governing Law</h4>
          <p>These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of law principles.</p>

          <h4>9. Modifications to Terms</h4>
          <p>We reserve the right to modify these Terms at any time. Changes take effect immediately upon posting. Your continued use of MineIt after any changes constitutes acceptance of the modified Terms.</p>

          <div className="mt-4 pt-3 border-top">
            <p className="text-warning">By using MineIt, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.</p>
          </div>
        </div>
      </div>

      <style>
        {`
          .terms-overlay {
            position: fixed;
            inset: 0;
            background: rgba(0, 0, 0, 0.9);
            backdrop-filter: blur(8px);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1050;
            animation: fadeIn 0.3s ease;
          }

          .terms-modal {
            background: linear-gradient(165deg, rgba(30, 34, 38, 0.95), rgba(20, 24, 28, 0.95));
            border-radius: 20px;
            padding: 2rem;
            width: 90%;
            max-width: 800px;
            max-height: 85vh;
            overflow-y: auto;
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
            animation: slideUp 0.4s ease;
            color: white;
          }

          .gradient-text {
            background: linear-gradient(45deg, #fff, #4a90e2);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 2rem;
            font-weight: bold;
          }

          .terms-content {
            line-height: 1.6;
          }

          .terms-content h4 {
            color: #4a90e2;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
          }

          .terms-content p, .terms-content ul {
            color: #ccc;
            margin-bottom: 1rem;
          }

          .terms-content ul {
            padding-left: 1.5rem;
          }

          .terms-content li {
            margin-bottom: 0.5rem;
          }

          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes slideUp {
            from { transform: translateY(30px); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default TermsOfUse;
