import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  AuthError
} from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, setDoc, getDoc, collection, query, where, getDocs, FirestoreError } from 'firebase/firestore';

// Custom error type for authentication
export class AuthenticationError extends Error {
  code?: string;

  constructor(message: string, code?: string) {
    super(message);
    this.name = 'AuthenticationError';
    this.code = code;
  }
}

const checkUsernameExists = async (username: string): Promise<boolean> => {
    const usersRef = collection(db, 'players');
    const q = query(usersRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};

export const registerUser = async (email: string, password: string, username: string) => {
  try {
    // Input validation
    if (!email || !password || !username) {
      throw new AuthenticationError('All fields are required');
    }

    // Check username availability
    const usernameExists = await checkUsernameExists(username);
    if (usernameExists) {
      throw new AuthenticationError('Username already taken', 'auth/username-taken');
    }

    // Attempt to create user
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Create user document
    await setDoc(doc(db, 'players', user.uid), {
      username,
      stats: {
        autoMiners: 0,
        autoMinerSpeed: 1,
        quarries: 0,
        quarrySpeed: 500,
        miningLuck: 0,
        valueMultiplier: 1,
        multiMineLevel: 0
      },
      inventory: {},
      totalCollection: {},
      currentPickaxeId: null,
      createdAt: new Date(),
      lastLogin: new Date()
    });

    return user;
  } catch (error: any) {
    // Log the full error for debugging
    console.error('Registration error:', error);

    // Handle Firebase Auth errors
    if (error instanceof FirestoreError || error?.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
        case 'auth/email-already-exists':
          throw new AuthenticationError('An account with this email already exists', error.code);
        case 'auth/invalid-email':
          throw new AuthenticationError('Invalid email address format', error.code);
        case 'auth/operation-not-allowed':
          throw new AuthenticationError('Email/password accounts are not enabled. Please contact support.', error.code);
        case 'auth/weak-password':
          throw new AuthenticationError('Password should be at least 6 characters', error.code);
        case 'auth/network-request-failed':
          throw new AuthenticationError('Network error. Please check your connection.', error.code);
        case 'permission-denied':
        case 'auth/insufficient-permission':
          throw new AuthenticationError('An account with this email already exists', 'auth/email-already-in-use');
        default:
          if (error.message?.includes('permission')) {
            throw new AuthenticationError('An account with this email already exists', 'auth/email-already-in-use');
          }
          throw new AuthenticationError(
            'An unexpected error occurred during registration. Please try again.',
            error.code
          );
      }
    }

    // Handle custom errors (like username taken)
    if (error instanceof AuthenticationError) {
      throw error;
    }

    // Handle any other unexpected errors
    throw new AuthenticationError('An unexpected error occurred during registration. Please try again.');
  }
};

export const signIn = async (email: string, password: string) => {
  try {
    if (!email || !password) {
      throw new AuthenticationError('Email and password are required');
    }

    const userCredential = await signInWithEmailAndPassword(auth, email, password);

    // Update last login
    await setDoc(doc(db, 'players', userCredential.user.uid), {
      lastLogin: new Date()
    }, { merge: true });

    return userCredential.user;
  } catch (error: any) {
    // Log the full error for debugging
    console.error('Login error:', error);

    const errorCode = error?.code;
    let errorMessage: string;

    switch (errorCode) {
      case 'auth/invalid-credential':
        errorMessage = 'Your username or password is incorrect.';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Incorrect password';
        break;
      case 'auth/invalid-email':
        errorMessage = 'Invalid email address format';
        break;
      case 'auth/user-disabled':
        errorMessage = 'This account has been disabled';
        break;
      case 'auth/too-many-requests':
        errorMessage = 'Too many failed login attempts. Please try again later.';
        break;
      case 'auth/network-request-failed':
        errorMessage = 'Network error. Please check your connection.';
        break;
      default:
        errorMessage = 'An unexpected error occurred during login';
    }

    throw new AuthenticationError(errorMessage, errorCode);
  }
};

export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    console.error('Signout error:', error);
    throw new AuthenticationError(
      'Failed to sign out. Please try again.',
      error?.code
    );
  }
};

// Helper to get current user's data
export const getCurrentUserData = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new AuthenticationError('No user is currently signed in');
    }

    const userDoc = await getDoc(doc(db, 'players', user.uid));
    if (!userDoc.exists()) {
      throw new AuthenticationError('User data not found');
    }

    return userDoc.data();
  } catch (error: any) {
    console.error('Get user data error:', error);
    throw new AuthenticationError(
      'Failed to fetch user data. Please try again.',
      error?.code
    );
  }
};
