import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ScrollText, X, Pickaxe, Bot } from 'lucide-react';
import { Player } from './Player';
import { ores } from './OreList';
import './MiningLog.css';

interface MiningLogProps {
  player: Player;
}

interface LogEntry {
  id: number;
  oreName: string;
  value: number;
  color: string;
  timestamp: Date;
  isAutoMined: boolean;
}

// Create an interface for the ref methods
export interface MiningLogRef {
  addLogEntry: (oreName: string, value: number, isAutoMined: boolean) => void;
}

const MiningLog = forwardRef<MiningLogRef, MiningLogProps>(({ player }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const maxLogs = 100;

  const addLogEntry = (oreName: string, value: number, isAutoMined: boolean) => {
    const ore = ores.find(o => o.name === oreName);
    if (!ore) return;

    const newEntry: LogEntry = {
      id: Date.now() + Math.random(),
      oreName,
      value,
      color: ore.color,
      timestamp: new Date(),
      isAutoMined
    };

    setLogs(prevLogs => [newEntry, ...prevLogs].slice(0, maxLogs));
  };

  // Expose the addLogEntry method through the ref
  useImperativeHandle(ref, () => ({
    addLogEntry
  }));

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="legend-button d-flex align-items-center"
      >
        <ScrollText size={20} />
        <span className="ms-2">Log</span>
      </button>

      {isOpen && (
        <div className="modal-overlay d-flex align-items-center justify-content-center">
          <div className="legend-modal">
            <button
              onClick={() => setIsOpen(false)}
              className="close-button"
            >
              <X size={24} />
            </button>

            <div className="text-center mb-4 pb-3 border-bottom border-secondary">
              <h2 className="legend-title">Mining Log</h2>
              <p className="white">Recent mining activity</p>
            </div>

            <div className="mining-log-container">
              {logs.length === 0 ? (
                <div className="text-center py-5 white">
                  <ScrollText size={48} className="mb-3 opacity-50" />
                  <h3>No mining activity yet</h3>
                  <p>Start mining to see your activity log!</p>
                </div>
              ) : (
                <div className="log-entries">
                  {logs.map((log) => (
                    <div
                      key={log.id}
                      className="log-entry d-flex align-items-center animate-fade-in"
                    >
                      <div className="log-icon me-3">
                        {log.isAutoMined ? (
                          <Bot size={16} className="text-cyan-400" />
                        ) : (
                          <Pickaxe size={16} className="text-orange-400" />
                        )}
                      </div>
                      <div className="log-content flex-grow-1">
                        <span className="ore-name" style={{ color: log.color }}>
                          {log.oreName}
                        </span>
                        <span className="text-success ms-2">
                          +${log.value.toLocaleString()}
                        </span>
                      </div>
                      <small className="text-muted ms-3">
                        {log.timestamp.toLocaleTimeString()}
                      </small>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

// Add a display name for the component (good practice with forwardRef)
MiningLog.displayName = 'MiningLog';

export default MiningLog;
