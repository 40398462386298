import React, { useState, useEffect } from 'react';
import { registerUser, signIn } from './firebaseAuth';
import { auth } from './firebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import CaveBackground from './CaveBackground';
import ForgotPassword from './ForgotPassword';
import GlobalLeaderboard from './GlobalLeaderboard';
import TermsOfUse from './TermsOfUse';
import logo from './logo.svg';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRegistering, setIsRegistering] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundOreColor, setBackgroundOreColor] = useState('#4a90e2');

  // Check if user is already logged in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const from = (location.state as any)?.from || '/game';
        navigate(from, { replace: true });
      }
    });

    return () => unsubscribe();
  }, [navigate, location]);

  // Background color animation
  useEffect(() => {
    const colors = ['#4a90e2', '#50c878', '#ffd700', '#ff69b4', '#9370db'];
    let colorIndex = 0;
    let isMounted = true;

    const interval = setInterval(() => {
      if (isMounted) {
        colorIndex = (colorIndex + 1) % colors.length;
        setBackgroundOreColor(colors[colorIndex]);
      }
    }, 3000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (isRegistering) {
        if (!acceptedTerms) {
          setError('You must accept the Terms of Use to register');
          setIsLoading(false);
          return;
        }

        if (username.length < 3) {
          setError('Username must be at least 3 characters long');
          setIsLoading(false);
          return;
        }

        if (password.length < 6) {
          setError('Password must be at least 6 characters long');
          setIsLoading(false);
          return;
        }

        await registerUser(email, password, username);
      } else {
        await signIn(email, password);
      }
      // No need to navigate - auth state change will handle it
    } catch (err: any) {
      console.error('Auth error:', err);
      setError(err.message);
      setIsLoading(false);
    }
  };

  const switchMode = () => {
    setIsRegistering(!isRegistering);
    setError('');
    setEmail('');
    setPassword('');
    setUsername('');
    setAcceptedTerms(false);
  };

  if (isForgotPassword) {
    return (
      <div className="login-wrapper">
        <CaveBackground currentOreColor={backgroundOreColor} />
        <div className="login-container">
          <div className="login-box">
            <img src={logo} alt="Logo" className="login-logo" />
            <ForgotPassword onBack={() => setIsForgotPassword(false)} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login-wrapper">
      <CaveBackground currentOreColor={backgroundOreColor} />
      <div className="login-container">
        <div className="login-box">
          <img src={logo} alt="Logo" className="login-logo" />
          <h2>{isRegistering ? 'Register' : 'Login'}</h2>

          {error && <div className="error-message">{error}</div>}

          <form onSubmit={handleSubmit}>
            {isRegistering && (
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value.trim())}
                  className="cave-input"
                  required
                  minLength={3}
                  maxLength={20}
                />
              </div>
            )}

            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                className="cave-input"
                required
              />
            </div>

            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="cave-input"
                required
                minLength={6}
              />
            </div>

            {isRegistering && (
              <div className="form-group terms-checkbox">
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                  />
                  <span className="checkmark"></span>
                  <span className="terms-text">
                    I agree to the{' '}
                    <button
                      type="button"
                      className="terms-link"
                      onClick={() => setShowTerms(true)}
                    >
                      Terms of Use
                    </button>
                  </span>
                </label>
              </div>
            )}

            {!isRegistering && (
              <div className="forgot-password-link">
                <button
                  type="button"
                  onClick={() => setIsForgotPassword(true)}
                  className="text-button"
                >
                  Forgot Password?
                </button>
              </div>
            )}

            <button
              type="submit"
              className={`submit-btn ${isLoading ? 'loading' : ''}`}
              disabled={isLoading}
              style={{
                background: `linear-gradient(45deg, ${backgroundOreColor}88, ${backgroundOreColor}44)`,
                borderColor: backgroundOreColor
              }}
            >
              {isLoading ? (
                <div className="crystal-loader">
                  <div className="crystal" style={{ backgroundColor: backgroundOreColor }}></div>
                  <div className="crystal" style={{ backgroundColor: backgroundOreColor }}></div>
                  <div className="crystal" style={{ backgroundColor: backgroundOreColor }}></div>
                </div>
              ) : (
                isRegistering ? 'Register' : 'Login'
              )}
            </button>
          </form>

          <p className="toggle-text">
            {isRegistering ? 'Already have an account?' : "Don't have an account?"}
            <button
              className="toggle-btn"
              onClick={switchMode}
            >
              {isRegistering ? 'Login' : 'Register'}
            </button>
          </p>

          <button
            className="leaderboard-btn"
            onClick={() => setShowLeaderboard(true)}
            style={{
              background: `linear-gradient(45deg, ${backgroundOreColor}44, ${backgroundOreColor}22)`,
              borderColor: backgroundOreColor
            }}
          >
            View Global Rankings
          </button>
        </div>
      </div>

      <GlobalLeaderboard isOpen={showLeaderboard} onClose={() => setShowLeaderboard(false)} />
      <TermsOfUse isOpen={showTerms} onClose={() => setShowTerms(false)} />

      <style>
        {`
          .login-wrapper {
            min-height: 100vh;
            width: 100vw;
            position: relative;
            overflow: hidden;
          }

          .login-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            z-index: 1;
          }

          .login-box {
            background: rgba(33, 37, 41, 0.85);
            backdrop-filter: blur(10px);
            padding: 2.5rem;
            border-radius: 15px;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.3),
                        inset 0 0 15px rgba(255, 255, 255, 0.05);
            width: 100%;
            max-width: 700px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            animation: fadeIn 0.5s ease-out;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .login-logo {
            width: 70%;
            max-width: 400px;
            height: auto;
            margin: 0 auto 1rem;
            display: block;
            animation: glow 2s infinite;
            transform: translateX(-22px);
          }

          .login-box h2 {
            color: white;
            text-align: center;
            margin-bottom: 2rem;
            font-size: 2rem;
            text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
          }

          .form-group {
            margin-bottom: 1.5rem;
            max-width: 200px;
            border-color: none;
          }

          .cave-input {
            width: 140%;
            transform: translateX(-40px);
            padding: 0.8rem;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            color: white;
            font-size: 1rem;
            transition: all 0.3s ease;
          }

          .cave-input:focus {
            outline: none;
            border-color: #0d6efd;
            box-shadow: 0 0 15px rgba(13, 110, 253, 0.3);
          }

          .submit-btn {
            width: 100%;
            padding: 0.8rem;
            border: none;
            border-radius: 8px;
            color: white;
            font-size: 1rem;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;
            overflow: hidden;
          }

          .submit-btn:not(:disabled):hover {
            transform: translateY(-2px);
            box-shadow: 0 0 20px rgba(13, 110, 253, 0.4);
          }

          .submit-btn:not(:disabled):active {
            transform: translateY(1px);
          }

          .error-message {
            background: rgba(255, 0, 0, 0.1);
            border: 1px solid rgba(255, 0, 0, 0.2);
            color: #ff6b6b;
            padding: 1rem;
            border-radius: 8px;
            margin-bottom: 1.5rem;
            text-align: center;
            animation: shake 0.5s ease-in-out;
          }

          .terms-checkbox {
            text-align: left;
            margin-bottom: 1rem;
          }

          .custom-checkbox {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            gap: 0.5rem;
          }

          .terms-link {
            background: none;
            border: none;
            color: ${backgroundOreColor};
            padding: 0;
            text-decoration: underline;
            cursor: pointer;
            transition: color 0.3s;
          }

          .terms-link:hover {
            color: ${backgroundOreColor}dd;
          }

          .terms-text {
            font-size: 0.9rem;
          }

          .crystal-loader {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
          }

          .crystal {
            width: 8px;
            height: 8px;
            margin: 0 4px;
            animation: crystal-bounce 1s infinite ease-in-out;
          }

          .crystal:nth-child(1) { animation-delay: -0.32s; }
          .crystal:nth-child(2) { animation-delay: -0.16s; }

          .toggle-text {
            text-align: center;
            margin-top: 1.5rem;
            color: rgba(255, 255, 255, 0.6);
          }

          .toggle-btn {
            background: none;
            border: none;
            color: ${backgroundOreColor};
            padding: 0;
            margin-left: 0.5rem;
            cursor: pointer;
            transition: color 0.3s;
          }

          .toggle-btn:hover {
            color: ${backgroundOreColor}dd;
          }

          .text-button {
            background: none;
            border: none;
            color: #0d6efd;
            padding: 0;
            font-size: 0.9rem;
            cursor: pointer;
            transition: all 0.3s ease;
            transform: translate(40px, 130px);
          }

          .text-button:hover {
            color: #0043a8;
            text-shadow: 0 0 10px rgba(13, 110, 253, 0.4);
          }

          .leaderboard-btn {
            margin-top: 2rem;
            padding: 0.75rem 1.5rem;
            border-radius: 25px;
            border: 1px solid ${backgroundOreColor}44;
            color: white;
            cursor: pointer;
            transition: all 0.3s ease;
            font-size: 0.9rem;
            width: 100%;
            max-width: 200px;
            display: block;
            margin-left: auto;
            margin-right: auto;
          }

          .leaderboard-btn:hover {
            transform: translateY(-2px);
            box-shadow: 0 0 15px ${backgroundOreColor}44;
          }

          .leaderboard-btn:active {
            transform: translateY(0);
          }

          @keyframes crystal-bounce {
            0%, 80%, 100% { transform: scale(0.6); }
            40% { transform: scale(1); }
          }

          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(-10px); }
            to { opacity: 1; transform: translateY(0); }
          }

          @keyframes shake {
            0%, 100% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            75% { transform: translateX(10px); }
          }

          @keyframes glow {
            0%, 100% { filter: drop-shadow(0 0 5px rgba(13, 110, 253, 0.5)); }
            50% { filter: drop-shadow(0 0 15px rgba(13, 110, 253, 0.8)); }
          }

          @media (max-width: 480px) {
            .login-box {
              margin: 1rem;
              padding: 1.5rem;
            }

            .login-logo {
              width: 100px;
            }

            .login-box h2 {
              font-size: 1.5rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Login;
