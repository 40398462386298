import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MiningHandler from './components/MiningHandler';
import CaveBackground from './components/CaveBackground';
import Stats from './components/Stats';
import { Player } from './components/Player';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Shop from './components/Shop';
import { AutoMiner } from './components/AutoMiner';
import OreLegend from './components/OreLegend';
import Inventory from './components/Inventory';
import MiningLog, { MiningLogRef } from './components/MiningLog';
import { Ore } from './components/Ore';
import RankList from './components/RankList';
import Crafting from './components/Crafting';
import { Pickaxe } from './components/Pickaxe';
import { QuarryManager } from './components/QuarryManager';
import Login from './components/Login';
import { auth } from './components/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { loadPlayerData, savePlayerData, initializePlayerFromData } from './components/firebaseData';
import LogoutButton from './components/LogoutButton';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import {BackgroundSelector} from './components/BackgroundSelector';

// Loading Screen Component
const LoadingScreen: React.FC = () => (
  <div className="loading-screen">
    <div className="loader">Loading...</div>
  </div>
);

// Protected Route Component
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login', { 
          state: { from: location.pathname },
          replace: true 
        });
      }
      setIsAuthChecked(true);
    });

    return () => unsubscribe();
  }, [navigate, location]);

  if (!isAuthChecked) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

// Game Component
const Game: React.FC = () => {
  const navigate = useNavigate();
  const [player, setPlayer] = useState<Player | null>(null);
  const [loading, setLoading] = useState(true);
  const [autoMiner, setAutoMiner] = useState<AutoMiner | null>(null);
  const [quarryManager, setQuarryManager] = useState<QuarryManager | null>(null);
  const miningLogRef = useRef<MiningLogRef>(null);
  const [currentPickaxe, setCurrentPickaxe] = useState<Pickaxe | null>(null);
  const [currentOreColor, setCurrentOreColor] = useState<string>('#4a90e2');
  const [showSaveNotice, setShowSaveNotice] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  // Save to local storage backup
  const saveToLocalStorage = useCallback(() => {
    if (player && auth.currentUser) {
      const playerData = {
        username: player.name,
        stats: {
          autoMiners: player.autoMiners,
          autoMinerSpeed: player.autoMinerSpeed,
          quarries: player.quarries,
          quarrySpeed: player.quarrySpeed,
          miningLuck: player.miningLuck,
          valueMultiplier: player.valueMultiplier,
          multiMineLevel: player.multiMineLevel
        },
        inventory: player.getInventory(),
        totalCollection: player.getTotalCollection(),
        lastUpdated: new Date().toISOString()
      };
      localStorage.setItem(`playerData_${auth.currentUser.uid}`, JSON.stringify(playerData));
    }
  }, [player]);

  // Save game to database
  const saveToDatabase = async () => {
    if (!player || !auth.currentUser) return;
    try {
      setIsSaving(true);
      await savePlayerData(auth.currentUser.uid, player);
      console.log('Game saved successfully');
    } catch (error) {
      console.error('Error saving game:', error);
    } finally {
      setIsSaving(false);
    }
  };

  // Initial game load
  useEffect(() => {
    const loadGame = async () => {
      try {
        const firebaseUser = auth.currentUser;
        if (!firebaseUser) {
          navigate('/login');
          return;
        }

        const localData = localStorage.getItem(`playerData_${firebaseUser.uid}`);
        let playerData;
        
        if (localData) {
          const serverData = await loadPlayerData(firebaseUser.uid);
          const localParsed = JSON.parse(localData);
          
          if (new Date(localParsed.lastUpdated) > new Date(serverData.lastUpdated)) {
            playerData = localParsed;
          } else {
            playerData = serverData;
          }
        } else {
          playerData = await loadPlayerData(firebaseUser.uid);
        }

        const newPlayer = new Player(playerData.username);
        initializePlayerFromData(newPlayer, playerData);

        const newAutoMiner = new AutoMiner(newPlayer);
        const newQuarryManager = new QuarryManager(newPlayer);

        setPlayer(newPlayer);
        setAutoMiner(newAutoMiner);
        setQuarryManager(newQuarryManager);
      } catch (error) {
        console.error('Error loading player data:', error);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    loadGame();
  }, [navigate]);

  // Handle tab close/refresh
  useEffect(() => {
    const handleBeforeUnload = async (e: BeforeUnloadEvent) => {
      if (player && auth.currentUser) {
        saveToLocalStorage();
        e.preventDefault();
        e.returnValue = '';
        await saveToDatabase();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [player, saveToLocalStorage]);

  // Local backup every minute
  useEffect(() => {
    const backupInterval = setInterval(saveToLocalStorage, 60000);
    return () => clearInterval(backupInterval);
  }, [saveToLocalStorage]);

  // Auto-mining and quarry systems
  useEffect(() => {
    if (player && autoMiner && quarryManager) {
      autoMiner.start();
      quarryManager.start();

      autoMiner.onMine((oreName, value, isAutoMined, ore?: Ore) => {
        if (miningLogRef.current) {
          miningLogRef.current.addLogEntry(oreName, value, isAutoMined);
        }
        if (ore) {
          setCurrentOreColor(ore.color);
        }
      });

      quarryManager.onMine((oreName, value, isAutoMined, ore?: Ore) => {
        if (miningLogRef.current) {
          miningLogRef.current.addLogEntry(oreName, value, isAutoMined);
        }
        if (ore) {
          setCurrentOreColor(ore.color);
        }
      });

      return () => {
        autoMiner.stop();
        quarryManager.stop();
      };
    }
  }, [player, autoMiner, quarryManager]);

  const handlePickaxeCrafted = (pickaxe: Pickaxe) => {
    setCurrentPickaxe(pickaxe);
  };

  const handleManualMine = (ore: Ore) => {
    if (miningLogRef.current) {
      miningLogRef.current.addLogEntry(ore.name, ore.value, false);
    }
    setCurrentOreColor(ore.color);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (!player) {
    return null;
  }

  return (
    <>
      <div className="Background">
        <CaveBackground currentOreColor={currentOreColor} />
      </div>

      {showSaveNotice && (
        <div style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 1000,
          background: 'rgba(0, 0, 0, 0.9)',
          color: 'white',
          padding: '1rem',
          borderRadius: '8px',
          maxWidth: '300px'
        }}>
          <div style={{ marginBottom: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <strong>Game Save Information</strong>
            <button 
              onClick={() => setShowSaveNotice(false)}
              style={{ 
                background: 'none', 
                border: 'none', 
                color: 'white',
                cursor: 'pointer',
                fontSize: '1.5rem',
                lineHeight: 1
              }}
            >
              ×
            </button>
          </div>
          <div>
            Your game saves automatically when you logout or close the tab. 
            Local backups are created every minute for safety.
          </div>
        </div>
      )}

      {isSaving && (
        <div style={{
          position: 'fixed',
          bottom: '1rem',
          left: '1rem',
          color: '#ffd700',
          fontSize: '0.9rem',
          background: 'rgba(0, 0, 0, 0.5)',
          padding: '0.5rem 1rem',
          borderRadius: '8px',
          backdropFilter: 'blur(4px)',
          zIndex: 1000
        }}>
          Saving game...
        </div>
      )}

      <div className="MiningHandler">
        <MiningHandler
          player={player}
          onMine={handleManualMine}
          currentPickaxe={currentPickaxe}
        />
      </div>
      <div className="StatsPanel" style={{ color: 'white' }}>
        <Stats player={player} />
      </div>
      <div className="ButtonGroup">
        <div className="d-flex gap-3">
          <Shop player={player} />
          <OreLegend />
          <Inventory player={player} />
          <MiningLog ref={miningLogRef} player={player} />
          <RankList player={player} />
          <Crafting
            player={player}
            onPickaxeCrafted={handlePickaxeCrafted}
          />
          <BackgroundSelector currentOreColor={currentOreColor} />
          <LogoutButton player={player} />
        </div>
      </div>
    </>
  );
};

// Main App Component
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/game" 
          element={
            <ProtectedRoute>
              <Game />
            </ProtectedRoute>
          } 
        />
        <Route path="/" element={<Navigate to="/game" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
