import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth from "firebase/auth"
import { getFirestore } from "firebase/firestore"; // Import getFirestore from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKQ1niALPO_QGxpwMt6liEWhSyZzG1sJk",
  authDomain: "mineit-cfd2e.firebaseapp.com",
  projectId: "mineit-cfd2e",
  storageBucket: "mineit-cfd2e.appspot.com",
  messagingSenderId: "203918678251",
  appId: "1:203918678251:web:b5cf046e4c8892e05b46ae",
  measurementId: "G-RGZZE1ER16"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
