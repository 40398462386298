import React, { useState, useCallback } from 'react';
import { Ore } from './Ore';
import { ores } from './OreList';
import { Pickaxe as PickaxeIcon } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Player } from './Player';
import { Pickaxe } from './Pickaxe';

interface Particle {
  id: number;
  x: number;
  y: number;
  velocityX: number;
  velocityY: number;
  size: number;
  opacity: number;
  color: string;
  isOreShape?: boolean;
}

interface MiningHandlerProps {
  player: Player;
  onMine?: (ore: Ore) => void;
  currentPickaxe: Pickaxe | null;
}

interface MinedOre extends Ore {
  wasCritical: boolean;
  quantity: number;
}

const MiningHandler: React.FC<MiningHandlerProps> = ({ player, onMine, currentPickaxe }) => {
  const [minedOre, setMinedOre] = useState<MinedOre | null>(null);
  const [particles, setParticles] = useState<Particle[]>([]);

  const createParticles = useCallback((x: number, y: number, oreColor: string) => {
    const newParticles: Particle[] = [];
    
    // Create burst particles
    for (let i = 0; i < 12; i++) {
      const angle = (Math.PI * 2 * i) / 12;
      newParticles.push({
        id: Date.now() + i,
        x,
        y,
        velocityX: Math.cos(angle) * (3 + Math.random() * 2),
        velocityY: Math.sin(angle) * (3 + Math.random() * 2),
        size: 4 + Math.random() * 4,
        opacity: 1,
        color: oreColor,
      });
    }

    // Create ore-shaped particles
    for (let i = 0; i < 4; i++) {
      const angle = (Math.PI * 2 * i) / 4;
      newParticles.push({
        id: Date.now() + i + 12,
        x,
        y,
        velocityX: Math.cos(angle) * (2 + Math.random()),
        velocityY: Math.sin(angle) * (2 + Math.random()),
        size: 8 + Math.random() * 6,
        opacity: 1,
        color: oreColor,
        isOreShape: true,
      });
    }

    setParticles(prevParticles => [...prevParticles, ...newParticles]);
  }, []);

  React.useEffect(() => {
    if (particles.length === 0) return;

    const animationFrame = requestAnimationFrame(() => {
      setParticles(prevParticles =>
        prevParticles
          .map(particle => ({
            ...particle,
            x: particle.x + particle.velocityX,
            y: particle.y + particle.velocityY,
            velocityY: particle.velocityY + 0.2,
            opacity: Math.max(particle.opacity - 0.02, 0),
          }))
          .filter(particle => particle.opacity > 0)
      );
    });

    return () => cancelAnimationFrame(animationFrame);
  }, [particles]);

  const mineOre = (event: React.MouseEvent<HTMLButtonElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;

    // Calculate combined perks
    const combinedPerks = {
      rareOreChance: (currentPickaxe?.perks.rareOreChance || 0) + (player.miningLuck || 0) * 5,
      multiMineChance: (currentPickaxe?.perks.multiMineChance || 0) + (player.multiMineLevel || 0) * 10,
      criticalChance: currentPickaxe?.perks.criticalChance || 0,
      valueBonus: (currentPickaxe?.perks.valueBonus || 0) + ((player.valueMultiplier || 1) - 1) * 100
    };

    // Calculate adjusted ore rarities
    const adjustedOres = ores.map(ore => ({
      ...ore,
      rarity: ore.rarity < 1 ? ore.rarity * (1 + combinedPerks.rareOreChance / 100) : ore.rarity
    }));

    const adjustedTotalRarity = adjustedOres.reduce((acc, ore) => acc + ore.rarity, 0);
    const multiMineRoll = Math.random() * 100;
    const mineCount = multiMineRoll < combinedPerks.multiMineChance ? 2 : 1;
    const minedOres: MinedOre[] = [];

    // Process each mining attempt
    for (let i = 0; i < mineCount; i++) {
      let cumulativeRarity = 0;
      const currentRoll = Math.random();

      for (const ore of adjustedOres) {
        cumulativeRarity += ore.rarity / adjustedTotalRarity;
        if (currentRoll < cumulativeRarity) {
          const criticalHit = Math.random() * 100 < (combinedPerks.criticalChance || 0);
          const quantity = criticalHit ? 100 : 1;
          const valueMultiplier = 1 + (combinedPerks.valueBonus / 100);

          const modifiedOre: MinedOre = {
            ...ore,
            value: Math.floor(ore.value * valueMultiplier),
            wasCritical: criticalHit,
            quantity: quantity
          };

          minedOres.push(modifiedOre);
          player.addOre(modifiedOre, quantity);
          onMine?.(modifiedOre);
          break;
        }
      }
    }

    // Only show effects for the last mined ore
    if (minedOres.length > 0) {
      const lastOre = minedOres[minedOres.length - 1];
      createParticles(x, y, lastOre.color);
      setMinedOre(lastOre);

      // Update background color
      if (typeof window !== 'undefined') {
        window.dispatchEvent(new CustomEvent('oreColorChange', {
          detail: lastOre.color
        }));
      }
    }
  };

  const buttonStyle = currentPickaxe ? {
    background: currentPickaxe.style.background,
    boxShadow: `0 0 15px ${currentPickaxe.style.glow}`,
  } : {
    background: 'linear-gradient(145deg, #808080, #606060)',
    boxShadow: '0 4px 15px rgba(128,128,128,0.2)',
  };

  return (
    <div className="position-relative" style={{ zIndex: 1 }}>
      <style>
        {`
          @keyframes glow {
            0% { text-shadow: 0 0 5px currentColor, 0 0 10px currentColor, 0 0 15px currentColor; }
            50% { text-shadow: 0 0 10px currentColor, 0 0 20px currentColor, 0 0 25px currentColor; }
            100% { text-shadow: 0 0 5px currentColor, 0 0 10px currentColor, 0 0 15px currentColor; }
          }

          @keyframes pulse {
            0% { filter: brightness(1); }
            50% { filter: brightness(1.3); }
            100% { filter: brightness(1); }
          }

          @keyframes sparkle {
            0% { filter: brightness(1) drop-shadow(0 0 5px currentColor); }
            50% { filter: brightness(1.3) drop-shadow(0 0 15px currentColor); }
            100% { filter: brightness(1) drop-shadow(0 0 5px currentColor); }
          }

          @keyframes rotate {
            0% { transform: translate(-50%, -50%) rotate(0deg); }
            100% { transform: translate(-50%, -50%) rotate(360deg); }
          }

          .ore-text {
            animation: glow 2s ease-in-out infinite;
            font-weight: bold;
          }

          .results-container {
            margin-left: -15rem;
            margin-top: 2rem;
            display: inline-block;
            text-align: left;
          }

          .mine-button {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.2s, box-shadow 0.2s;
            position: relative;
            overflow: visible;
            ${currentPickaxe?.style.animation ? `animation: ${currentPickaxe.style.animation} 2s infinite;` : ''}
          }

          .mine-button:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 20px rgba(0,0,0,0.25);
          }

          .mine-button:active {
            transform: translateY(1px);
            box-shadow: 0 2px 10px rgba(0,0,0,0.2);
          }

          .particle {
            position: fixed;
            pointer-events: none;
            z-index: 1000;
          }

          .critical-text {
            color: #ff0000;
            animation: glow 2s ease-in-out infinite;
            text-shadow: 0 0 10px #ff0000;
          }

          .quantity-text {
            color: #ffd700;
            font-weight: bold;
          }
        `}
      </style>

      <div className="container py-4">
        <div className="text-center text-light">
          <button
            onClick={mineOre}
            className="mine-button"
            style={buttonStyle}
          >
            <PickaxeIcon size={32} color="white" />
          </button>

          {particles.map(particle => (
            <div
              key={particle.id}
              className="particle"
              style={particle.isOreShape ? {
                left: particle.x,
                top: particle.y,
                width: particle.size,
                height: particle.size,
                opacity: particle.opacity,
                clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
                backgroundColor: particle.color,
                boxShadow: `0 0 10px ${particle.color}`,
                transform: 'translate(-50%, -50%) rotate(45deg)',
                animation: 'rotate 1s linear infinite'
              } : {
                left: particle.x,
                top: particle.y,
                width: particle.size,
                height: particle.size,
                opacity: particle.opacity,
                backgroundColor: particle.color,
                borderRadius: '50%',
                boxShadow: `0 0 5px ${particle.color}`,
                transform: 'translate(-50%, -50%)'
              }}
            />
          ))}

          {minedOre && (
            <div className="results-container">
              <div className="bg-dark bg-opacity-75 p-4 rounded" style={{ maxWidth: '600px', minWidth: '300px' }}>
                <h2 className="text-light">
                  You mined: <span className="ore-text" style={{ color: minedOre.color }}>
                    {minedOre.name}
                  </span>
                  {minedOre.wasCritical && (
                    <span className="critical-text"> (CRITICAL!)</span>
                  )}
                </h2>
                <p className="lead mb-2">{minedOre.description}</p>
                <p className="lead">
                  Value: <span className="ore-text" style={{ color: 'lightgreen' }}>
                    ${(minedOre.value * minedOre.quantity).toLocaleString()}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MiningHandler;
