import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebaseConfig';
import './ForgotPassword.css';

interface ForgotPasswordProps {
  onBack: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'sending' | 'sent' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');
    setErrorMessage('');

    try {
      await sendPasswordResetEmail(auth, email);
      setStatus('sent');
    } catch (error: any) {
      setStatus('error');
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="forgot-password-container">
      <h2>Reset Password</h2>
      {status === 'sent' ? (
        <div className="success-message">
          <p>If there is an account associated with this e-mail, a password reset link has been e-mailed to you.</p>
          <button onClick={onBack} className="back-button">
            Back to Login
          </button>
        </div>
      ) : (
        <>
          <p className="instruction-text">
            Enter your email address and we'll send you a link to reset your password.
          </p>
          {errorMessage && (
            <div className="error-message">
              {errorMessage}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="forgot-input-box"
                required
              />
            </div>
            <button
              type="submit"
              className="submit-btn"
              disabled={status === 'sending'}
            >
              {status === 'sending' ? (
                <div className="crystal-loader">
                  <div className="crystal"></div>
                  <div className="crystal"></div>
                  <div className="crystal"></div>
                </div>
              ) : (
                'Send Reset Link'
              )}
            </button>
          </form>
          <button onClick={onBack} className="back-link">
            Back to Login
          </button>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
