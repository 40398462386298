import React, { useState } from 'react';
import { Book, Percent, Coins, X, Sparkle } from 'lucide-react';
import { ores } from './OreList';
import './OreLegend.css';
import {NumberFormatter} from './NumberFormatter'


const OreLegend: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const totalRarity = ores.reduce((acc, ore) => acc + ore.rarity, 0);
  const sortedOres = [...ores].sort((a, b) => b.value - a.value);

  const formatChance = (rarity: number, oreName: string): string => {
    const percentage = (rarity / totalRarity) * 100;

  // Check if it's one of the extremely rare ores
   if (oreName === 'Unobtanium' || oreName === 'Infinity Stone') {
      return '?';
   }
   if (oreName === 'Rhodium' || oreName === 'Osmium' || oreName === 'Uranium' ||
    oreName === 'Californium' || oreName === 'Francium' || oreName === 'Astatine' ||
    oreName === 'Antimatter' || oreName === 'Dark Matter') {
      return '< 1';
   }

  // For other ores, use the normal formatting
    if (percentage < 0.0001) {
      return percentage.toFixed(4);
    }
    return percentage.toFixed(2);
  };
  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="legend-button d-flex align-items-center"
      >
        <Book size={20} />
        <span className="ms-2">Ores</span>
      </button>

      {isOpen && (
        <div className="modal-overlay d-flex align-items-center justify-content-center">
          <div className="legend-modal">
            {/* Close button */}
            <button
              onClick={() => setIsOpen(false)}
              className="close-button"
            >
              <X size={24} />
            </button>

            {/* Header */}
            <div className="text-center mb-4 pb-3 border-bottom border-secondary">
              <h2 className="legend-title">Collectable Ore List</h2>
              <p className="white">Discover the treasures that await</p>
            </div>

            {/* Content */}
            <div className="container-fluid p-3">
              <div className="row g-4">
                {sortedOres.map((ore) => (
                  <div key={ore.name} className="col-md-6 col-lg-4">
                    <div className="ore-card">
                      {/* Ore Name Header */}
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3 className="ore-name" style={{ color: ore.color }}>
                          {ore.name}
                          <Sparkle className="ms-2" size={16} style={{ color: ore.color }} />
                        </h3>
                      </div>

                      {/* Stats Row */}
                      <div className="d-flex gap-2 mb-3">
                        <div className="ore-stat">
                          <Coins size={16} className="me-2" />
                          <span className="font-monospace">
                            {NumberFormatter.formatCurrency(ore.value)}
                          </span>
                        </div>
                        <div className="ore-stat">
                          <Percent size={16} className="me-2" />
                          <span className="font-monospace">
                            {formatChance(ore.rarity, ore.name)}
                          </span>
                        </div>
                      </div>

                      {/* Description */}
                      <p className="ore-description">
                        {ore.description}
                      </p>

                      {/* Rarity Indicator */}
                      <div
                        className="rarity-dot"
                        style={{
                          backgroundColor: ore.color,
                          opacity: ore.rarity < 10 ? 1 : 0.5
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OreLegend;
