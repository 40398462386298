import React, { useMemo, useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface FallingOre {
  id: number;
  x: number;
  y: number;
  size: number;
  rotation: number;
  speed: number;
  color: string;
  delay: number;
  depth: number;
}

interface DeepCaveBackgroundProps {
  currentOreColor: string;
}

const DeepCaveBackground: React.FC<DeepCaveBackgroundProps> = React.memo(({ currentOreColor = '#fff' }) => {
  const [fallingOres, setFallingOres] = useState<FallingOre[]>([]);

  const createFallingOre = useCallback((overrideY = -10) => ({
    id: Math.random(),
    x: Math.random() * 100,
    y: overrideY,
    size: 8 + Math.random() * 12,
    rotation: Math.random() * 360,
    speed: 0.5 + Math.random(),
    color: currentOreColor,
    delay: Math.random() * 2,
    depth: Math.random() * 100
  }), [currentOreColor]);

  useEffect(() => {
    const initialOres = Array.from({ length: 15 }, () => ({
      ...createFallingOre(),
      y: Math.random() * 100
    }));
    setFallingOres(initialOres);
  }, [createFallingOre]);

  useEffect(() => {
    let animationFrame: number;
    let lastTime = performance.now();
    
    const animate = (currentTime: number) => {
      const deltaTime = (currentTime - lastTime) / 16;
      lastTime = currentTime;

      setFallingOres(prevOres => 
        prevOres.map(ore => ({
          ...ore,
          y: ore.y > 110 ? -10 : ore.y + (ore.speed * deltaTime),
          rotation: (ore.rotation + ore.speed) % 360
        }))
      );

      animationFrame = requestAnimationFrame(animate);
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const caveElements = useMemo(() => {
    const elements = [];

    // Crystal veins in the walls
    const veinColors = ['#3a8bff', '#ff5e7a', '#50ffc5'];
    for (let i = 0; i < 8; i++) {
      const veinBranches = [];
      const startX = Math.random() * 100;
      const startY = Math.random() * 100;
      const color = veinColors[Math.floor(Math.random() * veinColors.length)];
      
      for (let j = 0; j < 3; j++) {
        veinBranches.push(
          <div
            key={`vein-${i}-${j}`}
            className="crystal-vein"
            style={{
              '--x': `${startX + (Math.random() * 40 - 20)}%`,
              '--y': `${startY + (Math.random() * 40 - 20)}%`,
              '--color': color,
              '--width': `${100 + Math.random() * 150}px`,
              '--angle': `${Math.random() * 360}deg`,
              '--delay': `${Math.random() * 5}s`
            } as React.CSSProperties}
          />
        );
      }
      elements.push(
        <div key={`vein-group-${i}`} className="vein-group">
          {veinBranches}
        </div>
      );
    }

    // Bioluminescent clusters
    const bioColors = ['#4dc3ff', '#ff5e7a', '#50ffc5', '#ffd23f'];
    for (let i = 0; i < 15; i++) {
      const color = bioColors[Math.floor(Math.random() * bioColors.length)];
      elements.push(
        <div
          key={`bio-${i}`}
          className="bio-cluster"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${Math.random() * 100}%`,
            '--color': color,
            '--size': `${20 + Math.random() * 30}px`,
            '--delay': `${Math.random() * 5}s`
          } as React.CSSProperties}
        />
      );
    }

    // Light beams
    for (let i = 0; i < 3; i++) {
      elements.push(
        <div
          key={`beam-${i}`}
          className="light-beam"
          style={{
            '--x': `${20 + Math.random() * 60}%`,
            '--angle': `${-30 + Math.random() * 60}deg`,
            '--opacity': 0.1 + Math.random() * 0.1,
            '--delay': `${Math.random() * 10}s`
          } as React.CSSProperties}
        />
      );
    }

    // Floating particles
    for (let i = 0; i < 50; i++) {
      elements.push(
        <div
          key={`particle-${i}`}
          className="cave-particle"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${Math.random() * 100}%`,
            '--size': `${1 + Math.random() * 2}px`,
            '--delay': `${Math.random() * 10}s`,
            '--duration': `${20 + Math.random() * 20}s`
          } as React.CSSProperties}
        />
      );
    }

    return elements;
  }, []);

  return (
    <div className="deep-cave-background">
      <div className="cave-gradient" />
      <div className="cave-noise" />
      
      {caveElements}

      {fallingOres.map(ore => (
        <div
          key={ore.id}
          className="ore will-change"
          style={{
            '--x': `${ore.x}%`,
            '--y': `${ore.y}%`,
            '--size': `${ore.size}px`,
            '--rotation': `${ore.rotation}deg`,
            '--color': ore.color,
            '--delay': `${ore.delay}s`,
            '--depth': ore.depth
          } as React.CSSProperties}
        />
      ))}

      <div className="depth-fog" />

      <style>{`
        .deep-cave-background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #060714;
          overflow: hidden;
          z-index: -2;
        }

        .cave-gradient {
          position: absolute;
          width: 100%;
          height: 100%;
          background: 
            radial-gradient(circle at 30% 30%, rgba(58, 139, 255, 0.1), transparent 50%),
            radial-gradient(circle at 70% 70%, rgba(80, 255, 197, 0.1), transparent 50%);
          opacity: 0.5;
          animation: gradientShift 10s ease-in-out infinite;
        }

        .cave-noise {
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
          opacity: 0.3;
          mix-blend-mode: overlay;
        }

        .depth-fog {
          position: absolute;
          width: 100%;
          height: 100%;
          background: radial-gradient(circle at 50% 50%, transparent 0%, rgba(6, 7, 20, 0.8) 100%);
          pointer-events: none;
          z-index: 1;
        }

        .crystal-vein {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: var(--width);
          height: 2px;
          background: var(--color);
          transform: rotate(var(--angle));
          filter: blur(2px);
          opacity: 0;
          animation: veinPulse 4s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .bio-cluster {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: var(--size);
          height: var(--size);
          border-radius: 50%;
          background: radial-gradient(circle at center, var(--color), transparent 70%);
          filter: blur(4px);
          animation: bioGlow 4s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .light-beam {
          position: absolute;
          left: var(--x);
          top: -20%;
          width: 1px;
          height: 140%;
          background: linear-gradient(var(--angle), 
            transparent,
            rgba(255, 255, 255, var(--opacity)),
            transparent
          );
          filter: blur(3px);
          transform: rotate(var(--angle));
          animation: beamShift 10s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .cave-particle {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: var(--size);
          height: var(--size);
          background: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          filter: blur(1px);
          animation: particleFloat var(--duration) ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .ore {
          position: absolute;
          width: var(--size);
          height: var(--size);
          transform: translate3d(var(--x), var(--y), 0) rotate(var(--rotation));
          z-index: 0;
        }

        .ore::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--color);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          filter: drop-shadow(0 0 10px var(--color));
          animation: oreGlow 2s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        @keyframes veinPulse {
          0%, 100% { opacity: 0.1; width: var(--width); }
          50% { opacity: 0.3; width: calc(var(--width) * 1.2); }
        }

        @keyframes bioGlow {
          0%, 100% { opacity: 0.4; transform: scale(1); filter: blur(4px); }
          50% { opacity: 0.7; transform: scale(1.1); filter: blur(6px); }
        }

        @keyframes beamShift {
          0%, 100% { opacity: var(--opacity); transform: rotate(calc(var(--angle) - 5deg)); }
          50% { opacity: calc(var(--opacity) * 0.5); transform: rotate(calc(var(--angle) + 5deg)); }
        }

        @keyframes particleFloat {
          0%, 100% { transform: translate(0, 0); }
          25% { transform: translate(20px, -20px); }
          50% { transform: translate(-20px, -40px); }
          75% { transform: translate(-40px, -20px); }
        }

        @keyframes oreGlow {
          0%, 100% { filter: drop-shadow(0 0 10px var(--color)); }
          50% { filter: drop-shadow(0 0 20px var(--color)); }
        }

        @keyframes gradientShift {
          0%, 100% { opacity: 0.5; transform: scale(1); }
          50% { opacity: 0.7; transform: scale(1.1); }
        }
      `}</style>
    </div>
  );
});

DeepCaveBackground.displayName = 'DeepCaveBackground';

export default DeepCaveBackground;
