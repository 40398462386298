import { Player } from './Player';
import { Ore } from './Ore';
import { ores } from './OreList';

export class QuarryManager {
  private player: Player;
  private intervalId: NodeJS.Timeout | null = null;
  private onMineCallback: ((oreName: string, value: number, isAutoMined: boolean) => void) | null = null;

  constructor(player: Player) {
    this.player = player;
  }

  start() {
    // Clear any existing interval
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Run mining operation every 100ms (10 times per second)
    this.intervalId = setInterval(() => {
      if (this.player.quarries > 0) {
        const minesPerSecond = this.player.quarries * this.player.quarrySpeed;
        const minesThisTick = minesPerSecond / 10; // Divide by 10 since we're running 10 times per second

        // Mine multiple times based on quarry speed
        for (let i = 0; i < minesThisTick; i++) {
          this.mineOre();
        }
      }
    }, 100);
  }

  stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  onMine(callback: (oreName: string, value: number, isAutoMined: boolean) => void) {
    this.onMineCallback = callback;
  }

  private mineOre() {
    const totalRarity = ores.reduce((acc, ore) => acc + ore.rarity, 0);
    const randomNum = Math.random() * totalRarity;
    let cumulativeRarity = 0;

    for (const ore of ores) {
      cumulativeRarity += ore.rarity;
      if (randomNum < cumulativeRarity) {
        // Apply value multiplier
        const valueMultiplier = this.player.valueMultiplier || 1;
        const modifiedOre = {
          ...ore,
          value: Math.floor(ore.value * valueMultiplier)
        };

        // Add ore to player's inventory
        this.player.addOre(modifiedOre, 1);

        // Call the callback if it exists
        if (this.onMineCallback) {
          this.onMineCallback(modifiedOre.name, modifiedOre.value, true);
        }

        break;
      }
    }
  }
}
