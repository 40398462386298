
import React from 'react';
import { auth } from './firebaseConfig';
import { signOut } from 'firebase/auth';
import { savePlayerData } from './firebaseData';
import { Player } from './Player';

interface LogoutButtonProps {
  player: Player;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ player }) => {
  const handleLogout = async () => {
    try {
      const user = auth.currentUser;
      if (user && player) {
        await savePlayerData(user.uid, player);
      }
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <button onClick={handleLogout} className="btn btn-danger">
      Logout
    </button>
  );
};

export default LogoutButton;
