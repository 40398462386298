import React, { useState } from 'react';
import { Anvil, X } from 'lucide-react';
import { pickaxes, Pickaxe } from './Pickaxe';
import { Player } from './Player';
import './Crafting.css';

interface CraftingProps {
  player: Player;
  onPickaxeCrafted: (pickaxe: Pickaxe) => void;
}

const Crafting: React.FC<CraftingProps> = ({ player, onPickaxeCrafted }) => {
  const [isOpen, setIsOpen] = useState(false);

  const canCraft = (pickaxe: Pickaxe): boolean => {
    if (player.getLevel() < pickaxe.levelRequired) return false;

    return Object.entries(pickaxe.recipe).every(([oreName, amount]) =>
      player.getOreQuantity(oreName) >= amount  // Changed to check current inventory instead of total collection
    );
  };

  const craftPickaxe = (pickaxe: Pickaxe) => {
    if (!canCraft(pickaxe)) return;

    // Consume materials from current inventory
    Object.entries(pickaxe.recipe).forEach(([oreName, amount]) => {
      player.sellOre(oreName, amount);  // This will reduce the quantity and add money
    });

    onPickaxeCrafted(pickaxe);
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="crafting-button d-flex align-items-center"
      >
        <Anvil size={20} />
        <span className="ms-2">Forge</span>
      </button>

      {isOpen && (
        <div className="modal-overlay d-flex align-items-center justify-content-center">
          <div className="crafting-modal">
            <button onClick={() => setIsOpen(false)} className="close-button">
              <X size={24} />
            </button>

            <div className="text-center mb-4">
              <h2 className="crafting-title">Forge Pickaxes</h2>
              <p className="text-white opacity-75">Craft powerful pickaxes to enhance your mining!</p>
            </div>

            <div className="container-fluid">
              <div className="row g-3">
                {pickaxes.map(pickaxe => {
                  const canMake = canCraft(pickaxe);

                  return (
                    <div key={pickaxe.id} className="col-md-6">
                      <div className="pickaxe-card" style={{
                        background: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), ${pickaxe.style.background}`,
                        borderColor: canMake ? pickaxe.style.glow : 'transparent'
                      }}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h3 className="pickaxe-name" style={{ color: pickaxe.style.glow }}>{pickaxe.name}</h3>
                          <span className={`rarity-badge ${pickaxe.rarity.toLowerCase()}`}>{pickaxe.rarity}</span>
                        </div>

                        <p className="pickaxe-description text-white opacity-75">{pickaxe.description}</p>

                        <div className="perks-list mb-3">
                          {Object.entries(pickaxe.perks).map(([perk, value]) => (
                            <div key={perk} className="perk text-white opacity-75">
                              {perk.replace(/([A-Z])/g, ' $1').toUpperCase()}: +{value}%
                            </div>
                          ))}
                        </div>

                        <div className="recipe mb-3">
                          <h4 className="text-white opacity-75">Required Ores:</h4>
                          {Object.entries(pickaxe.recipe).map(([ore, amount]) => (
                            <div key={ore} className={`requirement ${
                              player.getOreQuantity(ore) >= amount ? 'text-success' : 'text-danger'
                            }`}>
                              {ore}: {player.getOreQuantity(ore)}/{amount}
                            </div>
                          ))}
                        </div>

                        <button
                          onClick={() => craftPickaxe(pickaxe)}
                          disabled={!canMake}
                          className="craft-button w-100"
                          style={{
                            background: canMake ? pickaxe.style.background : '#2a2a3a',
                            opacity: canMake ? 1 : 0.5
                          }}
                        >
                          {canMake ? 'Craft Pickaxe' : `Level ${pickaxe.levelRequired} Required`}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Crafting;
