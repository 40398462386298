import React, { useMemo, useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface FallingOre {
  id: number;
  x: number;
  y: number;
  size: number;
  rotation: number;
  speed: number;
  color: string;
  delay: number;
  depth: number;
}

interface CrystalBackgroundProps {
  currentOreColor: string;
}

const CrystalBackground: React.FC<CrystalBackgroundProps> = React.memo(({ currentOreColor = '#fff' }) => {
  const [fallingOres, setFallingOres] = useState<FallingOre[]>([]);

  const createFallingOre = useCallback((overrideY = -10) => ({
    id: Math.random(),
    x: Math.random() * 100,
    y: overrideY,
    size: 8 + Math.random() * 12,
    rotation: Math.random() * 360,
    speed: 0.5 + Math.random(),
    color: currentOreColor,
    delay: Math.random() * 2,
    depth: Math.random() * 100
  }), [currentOreColor]);

  useEffect(() => {
    const initialOres = Array.from({ length: 15 }, () => ({
      ...createFallingOre(),
      y: Math.random() * 100
    }));
    setFallingOres(initialOres);
  }, [createFallingOre]);

  useEffect(() => {
    let animationFrame: number;
    let lastTime = performance.now();
    
    const animate = (currentTime: number) => {
      const deltaTime = (currentTime - lastTime) / 16;
      lastTime = currentTime;

      setFallingOres(prevOres => 
        prevOres.map(ore => ({
          ...ore,
          y: ore.y > 110 ? -10 : ore.y + (ore.speed * deltaTime),
          rotation: (ore.rotation + ore.speed) % 360
        }))
      );

      animationFrame = requestAnimationFrame(animate);
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const crystalElements = useMemo(() => {
    const elements = [];

    // Large background crystals
    const bgColors = [
      '#4a9eff40', '#ff7f5040', '#50ff8d40', '#c576f640',
      '#ff63c340', '#45e3ff40', '#a64dff40', '#ffd70040'
    ];

    for (let i = 0; i < 12; i++) {
      const size = 100 + Math.random() * 200;
      elements.push(
        <div
          key={`bg-crystal-${i}`}
          className="background-crystal"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${Math.random() * 100}%`,
            '--size': `${size}px`,
            '--color': bgColors[Math.floor(Math.random() * bgColors.length)],
            '--rotation': `${Math.random() * 360}deg`,
            '--delay': `${Math.random() * 5}s`
          } as React.CSSProperties}
        />
      );
    }

    // Crystal clusters
    const crystalColors = [
      '#4a9eff', '#ff7f50', '#50ff8d', '#c576f6',
      '#ff63c3', '#45e3ff', '#a64dff', '#ffd700'
    ];

    for (let i = 0; i < 10; i++) {
      const baseColor = crystalColors[Math.floor(Math.random() * crystalColors.length)];
      const clusterSize = 30 + Math.random() * 40;
      const crystals = [];

      // Generate 4-7 crystals per cluster
      for (let j = 0; j < 4 + Math.floor(Math.random() * 4); j++) {
        crystals.push(
          <div
            key={`crystal-${i}-${j}`}
            className="crystal"
            style={{
              '--size': `${clusterSize * (0.5 + Math.random() * 0.5)}px`,
              '--x': `${Math.random() * 40 - 20}px`,
              '--y': `${Math.random() * 40 - 20}px`,
              '--z': `${Math.random() * 30 - 15}px`,
              '--color': baseColor,
              '--rotation': `${Math.random() * 360}deg`,
              '--delay': `${Math.random() * 3}s`
            } as React.CSSProperties}
          />
        );
      }

      elements.push(
        <div
          key={`cluster-${i}`}
          className="crystal-cluster"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${Math.random() * 100}%`,
            '--rotation': `${Math.random() * 360}deg`
          } as React.CSSProperties}
        >
          {crystals}
        </div>
      );
    }

    // Energy beams
    for (let i = 0; i < 6; i++) {
      elements.push(
        <div
          key={`beam-${i}`}
          className="energy-beam"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${Math.random() * 100}%`,
            '--rotation': `${Math.random() * 360}deg`,
            '--color': crystalColors[Math.floor(Math.random() * crystalColors.length)],
            '--delay': `${Math.random() * 5}s`,
            '--duration': `${5 + Math.random() * 5}s`
          } as React.CSSProperties}
        />
      );
    }

    // Floating particles
    for (let i = 0; i < 50; i++) {
      elements.push(
        <div
          key={`particle-${i}`}
          className="floating-particle"
          style={{
            '--x': `${Math.random() * 100}%`,
            '--y': `${Math.random() * 100}%`,
            '--size': `${2 + Math.random() * 3}px`,
            '--color': crystalColors[Math.floor(Math.random() * crystalColors.length)],
            '--delay': `${Math.random() * 5}s`,
            '--duration': `${10 + Math.random() * 20}s`
          } as React.CSSProperties}
        />
      );
    }

    return elements;
  }, []);

  return (
    <div className="crystal-cave-background">
      <div className="ambient-light" />
      <div className="crystal-fog" />
      
      {fallingOres.map(ore => (
        <div
          key={ore.id}
          className="ore will-change"
          style={{
            '--x': `${ore.x}%`,
            '--y': `${ore.y}%`,
            '--size': `${ore.size}px`,
            '--rotation': `${ore.rotation}deg`,
            '--color': ore.color,
            '--delay': `${ore.delay}s`,
            '--depth': ore.depth
          } as React.CSSProperties}
        />
      ))}

      {crystalElements}

      <style>{`
        .crystal-cave-background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: radial-gradient(circle at center, #1a1f2c, #0d1117);
          overflow: hidden;
          z-index: -2;
          perspective: 1000px;
        }

        .ambient-light {
          position: absolute;
          width: 100%;
          height: 100%;
          background: 
            radial-gradient(circle at 30% 30%, rgba(74, 158, 255, 0.05), transparent 60%),
            radial-gradient(circle at 70% 70%, rgba(197, 118, 246, 0.05), transparent 60%);
          mix-blend-mode: screen;
          animation: ambientPulse 8s ease-in-out infinite;
        }

        .crystal-fog {
          position: absolute;
          width: 100%;
          height: 100%;
          background: radial-gradient(circle at center, transparent 30%, rgba(13, 17, 23, 0.7));
          pointer-events: none;
        }

        .background-crystal {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: var(--size);
          height: var(--size);
          transform: rotate(var(--rotation));
          opacity: 0.2;
        }

        .background-crystal::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--color);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          filter: blur(40px);
          animation: crystalPulse var(--duration, 4s) ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .crystal-cluster {
          position: absolute;
          left: var(--x);
          top: var(--y);
          transform-style: preserve-3d;
          transform: rotate3d(1, 1, 1, var(--rotation));
        }

        .crystal {
          position: absolute;
          width: var(--size);
          height: var(--size);
          transform: translate3d(var(--x), var(--y), var(--z)) rotateZ(var(--rotation));
          animation: crystalGlow 3s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .crystal::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--color);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          box-shadow: 
            0 0 20px var(--color),
            0 0 40px var(--color),
            0 0 60px var(--color);
        }

        .energy-beam {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: 2px;
          height: 100px;
          background-color: var(--color);
          transform: rotate(var(--rotation));
          filter: blur(2px);
          opacity: 0;
          animation: beamPulse var(--duration) ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .floating-particle {
          position: absolute;
          left: var(--x);
          top: var(--y);
          width: var(--size);
          height: var(--size);
          background-color: var(--color);
          border-radius: 50%;
          filter: blur(1px);
          animation: particleFloat var(--duration) ease-in-out infinite;
          animation-delay: var(--delay);
        }

        .ore {
          position: absolute;
          width: var(--size);
          height: var(--size);
          transform: translate3d(calc(var(--x)), calc(var(--y)), calc(var(--depth) * 1px)) 
                     rotate(var(--rotation));
        }

        .ore::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--color);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          box-shadow: 0 0 20px var(--color);
          animation: oreGlow 2s ease-in-out infinite;
          animation-delay: var(--delay);
        }

        @keyframes crystalGlow {
          0%, 100% { opacity: 0.7; filter: brightness(1); }
          50% { opacity: 1; filter: brightness(1.5); }
        }

        @keyframes crystalPulse {
          0%, 100% { transform: scale(1); opacity: 0.3; }
          50% { transform: scale(1.1); opacity: 0.5; }
        }

        @keyframes beamPulse {
          0%, 100% { opacity: 0; height: 0; }
          50% { opacity: 0.5; height: 100px; }
        }

        @keyframes particleFloat {
          0%, 100% { transform: translate3d(0, 0, 0); }
          25% { transform: translate3d(20px, -20px, 20px); }
          50% { transform: translate3d(-20px, -40px, -20px); }
          75% { transform: translate3d(-40px, -20px, 40px); }
        }

        @keyframes ambientPulse {
          0%, 100% { opacity: 0.5; }
          50% { opacity: 0.7; }
        }

        @keyframes oreGlow {
          0%, 100% { filter: brightness(1) blur(0px); }
          50% { filter: brightness(1.3) blur(2px); }
        }
      `}</style>
    </div>
  );
});

CrystalBackground.displayName = 'CrystalBackground';

export default CrystalBackground;
