// backgrounds/BackgroundSelector.tsx

import React from 'react';
import { Settings } from 'lucide-react';
import  CaveBackground  from './CaveBackground';
import  CrystalBackground  from './CrystalBackground';
import  DeepCaveBackground  from './DeepCaveBackground';
import LiteCaveBackground from './LiteBackground';

interface BackgroundSelectorProps {
  currentOreColor: string;
}

export type BackgroundType = 'classic' | 'crystal' | 'deep' | 'lite';

const backgrounds = [
  { id: 'classic', name: 'Classic Cave', description: 'The original mining cave experience' },
  { id: 'crystal', name: 'Crystal Cavern', description: 'A mystical cave filled with glowing crystals' },
  { id: 'deep', name: 'Deep Cave', description: 'An immersive deep cave system' },
  { id: 'lite', name: 'Performance Mode', description: 'Optimized for low-end devices'}
] as const;

export const BackgroundSelector: React.FC<BackgroundSelectorProps> = ({ currentOreColor }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedBackground, setSelectedBackground] = React.useState<BackgroundType>('classic');
  
  // Get the active background component
  const ActiveBackground = React.useMemo(() => {
    switch (selectedBackground) {
      case 'crystal':
        return <CrystalBackground currentOreColor={currentOreColor} />;
      case 'deep':
        return <DeepCaveBackground currentOreColor={currentOreColor} />;
      case 'lite':
        return <LiteCaveBackground currentOreColor={currentOreColor} />;
      default:
        return <CaveBackground currentOreColor={currentOreColor} />;
    }
  }, [selectedBackground, currentOreColor]);

  return (
    <>
      {ActiveBackground}

      <div className="background-selector">
        <button 
          className="background-toggle"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Settings size={20} />
          <span>Background</span>
        </button>

        {isOpen && (
          <div className="background-menu">
            <h3>Select Background</h3>
            <div className="background-options">
              {backgrounds.map(bg => (
                <div
                  key={bg.id}
                  className={`background-option ${selectedBackground === bg.id ? 'selected' : ''}`}
                  onClick={() => {
                    setSelectedBackground(bg.id as BackgroundType);
                    setIsOpen(false);
                  }}
                >
                  <div className="preview-box" data-type={bg.id}>
                    {/* Preview animation specific to each background */}
                  </div>
                  <div className="option-info">
                    <h4>{bg.name}</h4>
                    <p>{bg.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <style>{`
        .background-selector {
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 1000;
        }

        .background-toggle {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 16px;
          background: rgba(0, 0, 0, 0.7);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          color: white;
          cursor: pointer;
          transition: all 0.3s ease;
          backdrop-filter: blur(10px);
        }

        .background-toggle:hover {
          background: rgba(0, 0, 0, 0.8);
          transform: translateY(-2px);
        }

        .background-menu {
          position: absolute;
          bottom: 100%;
          right: 0;
          margin-bottom: 10px;
          width: 300px;
          background: rgba(0, 0, 0, 0.9);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 12px;
          padding: 16px;
          backdrop-filter: blur(10px);
          animation: menuSlide 0.3s ease;
        }

        .background-menu h3 {
          color: white;
          margin: 0 0 16px 0;
          font-size: 1.1rem;
          opacity: 0.9;
        }

        .background-options {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .background-option {
          display: flex;
          gap: 12px;
          padding: 12px;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s ease;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .background-option:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        .background-option.selected {
          border-color: #4a9eff;
          background: rgba(74, 158, 255, 0.1);
        }

        .preview-box {
          width: 80px;
          height: 60px;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
        }

        .preview-box[data-type="classic"] {
          background: linear-gradient(to bottom, #1a1f2c, #0d1117);
        }

        .preview-box[data-type="crystal"] {
          background: linear-gradient(to bottom, #171a24, #12151c);
        }

        .preview-box[data-type="deep"] {
          background: linear-gradient(to bottom, #090a0f, #171a24);
        }

        .option-info {
          flex: 1;
        }

        .option-info h4 {
          color: white;
          margin: 0 0 4px 0;
          font-size: 0.9rem;
        }

        .option-info p {
          color: rgba(255, 255, 255, 0.6);
          margin: 0;
          font-size: 0.8rem;
        }

        @keyframes menuSlide {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </>
  );
};
