export interface RankInfo {
  rank: string;
  color?: string;
  gradient?: string;
  glow?: boolean;
  levelRequired: number;
}

export const ranks: RankInfo[] = [
  { rank: 'Novice', color: '#C0C0C0', levelRequired: 0 },
  { rank: 'Beginner', color: '#CD7F32', levelRequired: 20 },
  { rank: 'Journeyman', color: '#4169E1', levelRequired: 80 },
  { rank: 'Adept', color: '#32CD32', levelRequired: 400 },
  { rank: 'Master', color: '#9370DB', levelRequired: 600, glow: true },
  { rank: 'Grandmaster', color: '#FF4500', levelRequired: 6000, glow: true },
  { rank: 'Unreal', color: '#00CED1', levelRequired: 10000, glow: true },
  { rank: 'Legendary', color: '#FFD700', levelRequired: 20000, glow: true },
  { rank: 'Supreme', color: '#FF1493', levelRequired: 50000, glow: true },
  { rank: 'Mythic', color: '#FF00FF', levelRequired: 90000, glow: true },
  {
    rank: 'Druid',
    gradient: `linear-gradient(45deg, #ff00cc, #3333ff, #33cc33, #ffcc33, #ff33cc, #00ffcc)`,
    levelRequired: 150000,
    glow: true
  }
];

export const getRankInfo = (level: number): RankInfo => {
  for (let i = ranks.length - 1; i >= 0; i--) {
    if (level >= ranks[i].levelRequired) {
      return ranks[i];
    }
  }
  return ranks[0];
};
