import React, { useState, useEffect } from 'react';
import { Package, Coins, X, Archive } from 'lucide-react';
import { Player } from './Player';
import './Inventory.css';
import { ores } from './OreList'
import { NumberFormatter } from './NumberFormatter'

interface InventoryProps {
  player: Player;
}

const Inventory: React.FC<InventoryProps> = ({ player }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setTick] = useState(0);

  // Set up the interval for updates
  useEffect(() => {
    const interval = setInterval(() => {
      setTick(tick => tick + 1);
    }, 100);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Get inventory and sort by value
const inventory = player.getTotalCollectionSummary()
  .sort((a, b) => b.totalValue - a.totalValue)
  .filter(item => item.quantity > 0);
   // Calculate total value
  const totalValue = inventory.reduce((acc, item) => acc + item.totalValue, 0);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="legend-button d-flex align-items-center"
      >
        <Package size={20} />
        <span className="ms-2">Collection</span>
      </button>

      {isOpen && (
        <div className="modal-overlay d-flex align-items-center justify-content-center">
          <div className="legend-modal">
            {/* Rest of your component stays exactly the same */}
            <button
              onClick={() => setIsOpen(false)}
              className="close-button"
            >
              <X size={24} />
            </button>

            <div className="text-center mb-4 pb-3 border-bottom border-secondary">
              <h2 className="legend-title">{player.name}'s Collection</h2>
              <p className="text-muted d-flex align-items-center justify-content-center gap-3">
                <span className='oreTotal'>Total Ores: {NumberFormatter.format(inventory.reduce((acc, item) => acc + item.quantity, 0))}</span>
                <span>•</span>
                <span className='totalValue'>Total Value: {NumberFormatter.formatCurrency(totalValue)}</span>
              </p>
            </div>

            <div className="container-fluid p-3">
              {inventory.length === 0 ? (
                <div className="text-center py-5">
                  <Archive size={48} className="white mb-3" />
                  <h3 className="white">No ores collected yet</h3>
                  <p className="white">Start mining to build your collection!</p>
                </div>
              ) : (
                <div className="row g-4">
                  {inventory.map(({ oreName, quantity, totalValue }) => {
                    const oreDetails = ores.find(ore => ore.name === oreName);
                    if (!oreDetails) return null;

                    return (
                      <div key={oreName} className="col-md-6 col-lg-4">
                        <div className="ore-card">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="ore-name" style={{ color: oreDetails.color }}>
                              {oreName}
                            </h3>
                          </div>

                          <div className="d-flex gap-2 mb-3">
                            <div className="ore-stat">
                              <Package size={16} className="me-2" />
                              <span className="font-monospace">
                                {NumberFormatter.format(quantity)}
                              </span>
                            </div>
                            <div className="ore-stat">
                              <Coins size={16} className="me-2" />
                              <span className="font-monospace">
                                {NumberFormatter.format(totalValue)}
                              </span>
                            </div>
                          </div>

                          <div className="progress bg-dark" style={{ height: '8px' }}>
                            <div
                              className="progress-bar"
                              style={{
                                width: `${(quantity /
                                  Math.max(...inventory.map(i => i.quantity))) * 100}%`,
                                backgroundColor: oreDetails.color,
                                boxShadow: `0 0 10px ${oreDetails.color}`
                              }}
                            />
                          </div>

                          <div className="mt-2 text-end">
                            <small className="text-muted">
                              {NumberFormatter.format(oreDetails.value)} each
                            </small>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Inventory;
