export class NumberFormatter {
  private static readonly SUFFIXES = [
    { value: 1e63, symbol: 'V' },    // Vigintillion
    { value: 1e60, symbol: 'Nd' },   // Novemdecillion
    { value: 1e57, symbol: 'Od' },   // Octodecillion
    { value: 1e54, symbol: 'Sd' },   // Septendecillion
    { value: 1e51, symbol: 'Sd' },   // Sexdecillion
    { value: 1e48, symbol: 'Qd' },   // Quindecillion
    { value: 1e45, symbol: 'Qd' },   // Quattuordecillion
    { value: 1e42, symbol: 'T' },    // Tredecillion
    { value: 1e39, symbol: 'D' },    // Duodecillion
    { value: 1e36, symbol: 'U' },    // Undecillion
    { value: 1e33, symbol: 'D' },    // Decillion
    { value: 1e30, symbol: 'N' },    // Nonillion
    { value: 1e27, symbol: 'O' },    // Octillion
    { value: 1e24, symbol: 'S' },    // Septillion
    { value: 1e21, symbol: 'Sx' },   // Sextillion
    { value: 1e18, symbol: 'Qi' },   // Quintillion
    { value: 1e15, symbol: 'Qa' },   // Quadrillion
    { value: 1e12, symbol: 'T' },    // Trillion
    { value: 1e9, symbol: 'B' },     // Billion
    { value: 1e6, symbol: 'M' },     // Million
    { value: 1e3, symbol: 'K' },     // Thousand
  ];

  /**
   * Format a number with abbreviations (K, M, B, etc.)
   * @param num The number to format
   * @param digits Number of decimal places (default: 1)
   * @param forceDecimal Force showing decimal places even for whole numbers
   * @returns Formatted string
   */
  static format(num: number, digits: number = 1, forceDecimal: boolean = false): string {
    // Handle zero specially
    if (num === 0) return '0';

    // Handle negative numbers
    const isNegative = num < 0;
    num = Math.abs(num);

    // Find the appropriate suffix
    const suffix = this.SUFFIXES.find(x => num >= x.value);

    if (suffix) {
      // Calculate the shortened number
      const shortened = num / suffix.value;

      // Format with appropriate decimal places
      const formattedNumber = forceDecimal ?
        shortened.toFixed(digits) :
        this.formatDecimal(shortened, digits);

      return `${isNegative ? '-' : ''}${formattedNumber}${suffix.symbol}`;
    }

    // For small numbers, just return the number with commas
    return `${isNegative ? '-' : ''}${this.formatDecimal(num, digits)}`;
  }

  /**
   * Format a decimal number with the specified number of decimal places
   * @param num The number to format
   * @param digits Number of decimal places
   * @returns Formatted string
   */
  private static formatDecimal(num: number, digits: number): string {
    const formatted = num.toFixed(digits);
    if (digits === 0) return formatted;

    // Remove trailing zeros after decimal point
    return formatted.replace(/\.?0+$/, '');
  }

  /**
   * Format a number with commas
   * @param num The number to format
   * @returns Formatted string with commas
   */
  static formatWithCommas(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Format a number as a percentage
   * @param num The number to format (0-100)
   * @param digits Number of decimal places
   * @returns Formatted percentage string
   */
  static formatPercent(num: number, digits: number = 1): string {
    return `${this.formatDecimal(num, digits)}%`;
  }

  /**
   * Format a number as currency
   * @param num The number to format
   * @param digits Number of decimal places
   * @returns Formatted currency string
   */
  static formatCurrency(num: number, digits: number = 0): string {
    return `$${this.format(num, digits)}`;
  }

  /**
   * Format a rate (per second)
   * @param num The number to format
   * @param digits Number of decimal places
   * @returns Formatted rate string
   */
  static formatRate(num: number, digits: number = 1): string {
    return `${this.format(num, digits)}/s`;
  }

  /**
   * Parse a formatted string back to a number
   * @param str The formatted string to parse
   * @returns The parsed number
   */
  static parse(str: string): number {
    if (!str) return 0;

    // Remove commas and currency symbols
    str = str.replace(/[$,]/g, '');

    // Find the suffix
    const match = str.match(/^-?\d+\.?\d*([A-Za-z]+)?$/);
    if (!match) return 0;

    const num = parseFloat(str);
    const suffix = match[1];

    if (!suffix) return num;

    const suffixObj = this.SUFFIXES.find(x => x.symbol === suffix);
    return suffixObj ? num * suffixObj.value : num;
  }
}
