import { Player } from './Player';
import { Ore } from './Ore';
import { ores } from './OreList';

export type MiningCallback = (oreName: string, value: number, isAutoMined: boolean) => void;

export class AutoMiner {
  private player: Player;
  private isRunning: boolean = false;
  private miningInterval: NodeJS.Timeout | null = null;
  private accumulatedPartialMines: number = 0;
  private miningCallback?: MiningCallback;

  constructor(player: Player) {
    this.player = player;
  }

  onMine(callback: MiningCallback): void {
    this.miningCallback = callback;
  }

  start(): void {
    if (this.isRunning) return;

    this.isRunning = true;
    this.miningInterval = setInterval(() => {
      // Calculate mining attempts for this tick
      const minesPerSecond = this.player.autoMiners * this.player.autoMinerSpeed;
      this.accumulatedPartialMines += minesPerSecond;

      const minesToPerform = Math.floor(this.accumulatedPartialMines);
      this.accumulatedPartialMines -= minesToPerform;

      // Process each mining attempt
      for (let i = 0; i < minesToPerform; i++) {
        this.performSingleMine();
      }
    }, 1000);
  }

  private performSingleMine(): void {
    // Calculate ore probabilities with mining luck bonus
    const adjustedOres = ores.map(ore => ({
      ...ore,
      rarity: ore.rarity < 1 ? 
        ore.rarity * (1 + (this.player.miningLuck * 5) / 100) : 
        ore.rarity
    }));

    const totalRarity = adjustedOres.reduce((acc, ore) => acc + ore.rarity, 0);
    
    // Select an ore based on rarity
    const randomNum = Math.random();
    let cumulativeRarity = 0;

    // Check for multi-mine
    const multiMineRoll = Math.random() * 100;
    const quantity = multiMineRoll < (this.player.multiMineLevel * 10) ? 2 : 1;

    // Find which ore was mined
    for (const ore of adjustedOres) {
      cumulativeRarity += ore.rarity / totalRarity;
      if (randomNum < cumulativeRarity) {
        // Add original ore to inventory (value multiplier applied at sale)
        this.player.addOre(ore, quantity);

        // Notify via callback if registered
        if (this.miningCallback) {
          this.miningCallback(ore.name, ore.value, true);
        }
        break;
      }
    }
  }

  stop(): void {
    if (this.miningInterval) {
      clearInterval(this.miningInterval);
      this.miningInterval = null;
    }
    this.isRunning = false;
    this.accumulatedPartialMines = 0;
  }

  getMiningRate(): number {
    return this.player.autoMiners * this.player.autoMinerSpeed;
  }

  isActive(): boolean {
    return this.isRunning;
  }
}
