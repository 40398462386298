import { Ore } from './Ore';

const ores: Ore[] = [
  // Common Ores & Minerals (50-60 rarity)
  new Ore('Copper', 60, 'A common metal used in wiring and plumbing. Its reddish hue makes it easily identifiable.', 20, 'rgb(184, 115, 51)'),
  new Ore('Iron', 55, 'A strong and abundant metal, fundamental to construction and manufacturing, known for its durability.', 25, 'darkgray'),
  new Ore('Coal', 52, 'A combustible black sedimentary rock, essential for energy production and steel manufacturing.', 15, '#454545'),
  new Ore('Limestone', 50, 'A sedimentary rock primarily composed of calcium carbonate, used in construction and agriculture.', 10, '#E8E4D8'),

  // Uncommon Metals (20-49 rarity)
  new Ore('Lead', 45, 'A dense, malleable metal, once commonly used in pipes and paints, now known for its toxicity.', 10, 'gray'),
  new Ore('Tin', 40, 'A malleable metal with a silvery sheen, used in alloys and coatings.', 60, 'rgb(193, 193, 193)'),
  new Ore('Zinc', 35, 'A bluish-white metal used in galvanization and dietary supplements.', 70, '#D3D4D5'),
  new Ore('Nickel', 30, 'A silvery-white metal known for its resistance to corrosion and magnetic properties.', 85, '#A6A6A6'),
  new Ore('Silver', 25, 'An uncommon, shiny metal prized for its beauty and conductivity. Often used in jewelry and electronics.', 80, 'silver'),
  new Ore('Graphite', 20, 'A form of carbon known for its lubricating properties and used in batteries and pencils.', 90, 'darkgray'),

  // Semi-Precious Materials (10-19 rarity)
  new Ore('Gold', 15, 'A precious yellow metal revered for its rarity and value, often used in coins and jewelry.', 200, 'gold'),
  new Ore('Emerald', 12, 'A stunning green gemstone known for its vibrant color and rarity, often associated with luxury.', 2000, 'green'),
  new Ore('Topaz', 11, 'A gemstone available in various colors, known for its clarity and hardness.', 900, 'rgb(255, 200, 124)'),
  new Ore('Platinum', 10, 'A rare and valuable metal, highly resistant to corrosion and often used in high-end jewelry and catalytic converters.', 500, 'lightgray'),

  // Rare Elements (5-9 rarity)
  new Ore('Bismuth', 8, 'A brittle metal with a rainbow-colored sheen, known for its low toxicity and unique crystalline structure.', 200, 'rgb(123, 104, 238)'),
  new Ore('Lithium', 7, 'A soft, silvery-white metal used in rechargeable batteries and mood-stabilizing drugs.', 10000, 'whitesmoke'),
  new Ore('Titanium', 6, 'A lightweight and strong metal, highly valued in aerospace and medical applications for its durability.', 100000, 'slategray'),
  new Ore('Amethyst', 6, 'A purple variant of quartz prized for its color and used in jewelry.', 1000, 'purple'),

  // Very Rare Gems & Metals (2-4 rarity)
  new Ore('Diamond', 4, 'An exquisite gemstone formed under extreme pressure, prized for its brilliance and hardness.', 500000, 'lightblue'),
  new Ore('Ruby', 4, 'A precious red gemstone known for its vibrant color and rarity, often used in fine jewelry.', 2000, 'red'),
  new Ore('Sapphire', 3.5, 'A precious gemstone, typically blue but found in various colors, valued for its hardness and beauty.', 2000, 'blue'),
  new Ore('Kyawthuite', 3, 'An extremely rare mineral known for its striking reddish-brown color and high density.', 1000000, '#FFDAB9'),
  new Ore('Painite', 2.5, 'One of the rarest gemstones, featuring a unique reddish-brown color and complex crystal structure.', 1500000, '#8B4513'),
  new Ore('Tanzanite', 2, 'A rare blue-violet gemstone found only in Tanzania, known for its striking pleochroic colors.', 2000000, '#4B0082'),

  // Extremely Rare Elements (0.01-1 rarity)
  new Ore('Uranium', 0.8, 'A silvery-white metal known for its high density and radioactivity.', 50000000, '#39FF14'),
  new Ore('Californium', 0.6, 'A radioactive metal with potential applications in nuclear power and medicine.', 75000000, '#FF6B6B'),
  new Ore('Francium', 0.4, 'The rarest naturally occurring element, highly radioactive and extremely valuable for research.', 85000000, '#FFD700'),
  new Ore('Rhodium', 0.2, 'An extremely rare, silver-white metallic element, prized for its high reflectivity and corrosion resistance.', 100000000, 'rgb(160, 210, 190)'),
  new Ore('Astatine', 0.1, 'The rarest naturally occurring element on Earth, with extraordinary properties.', 500000000, '#FF1493'),
  new Ore('Antimatter', 0.05, 'Exotic matter composed of antiparticles, extremely valuable for scientific research.', 1000000000, '#00FFFF'),
  new Ore('Osmium', 0.007, 'One of the densest naturally occurring elements, known for its bluish-gray tint and metallic sheen.', 2000000000, '#00C9FF'),

  // Mythical/Special (Ultra Rare)
  new Ore('Dark Matter', 0.005, 'A mysterious substance that makes up most of the universe but is nearly impossible to detect.', 5000000000000, '#4B0082'),
  new Ore('Unobtanium', 0.004, 'An incredibly rare and mythical metal with extraordinary properties beyond human comprehension.', 9000000000000, 'rgb(58, 123, 213)'),
  new Ore('Infinity Stone', 0.001, 'A legendary crystal containing unfathomable power, its very existence defies scientific explanation.', 999999999999999, '#FF1493'),
]

export { ores };
