import { Ore } from './Ore'

interface OreInventory {
  [oreName: string]: {
    ore: Ore;
    quantity: number;
  };
}

export class Player {
  readonly username: string;
  private oreInventory: OreInventory;
  private totalCollectedOres: OreInventory;
  private money: number = 0;

  // Mining properties
  autoMiners: number = 0;
  autoMinerSpeed: number = 1;
  quarries: number = 0;
  quarrySpeed: number = 200;
  
  // Bonuses
  miningLuck: number = 0;
  valueMultiplier: number = 1;
  multiMineLevel: number = 0;
  
  // Features
  hasAutoSell: boolean = false;

  constructor(username: string, autoMiners: number = 0, autoMinerSpeed: number = 1) {
    this.username = username;
    this.oreInventory = {};
    this.totalCollectedOres = {};
    this.autoMiners = autoMiners;
    this.autoMinerSpeed = autoMinerSpeed;
  }

  // Money Management
  getMoney(): number {
    return this.money;
  }

  addMoney(amount: number): void {
    this.money += amount;
  }

  spendMoney(amount: number): boolean {
    if (this.money >= amount) {
      this.money -= amount;
      return true;
    }
    return false;
  }

  // Ore Management
  addOre(ore: Ore, quantity: number = 1): void {
    // Add to current inventory
    if (!this.oreInventory[ore.name]) {
      this.oreInventory[ore.name] = {
        ore,
        quantity: 0
      };
    }
    this.oreInventory[ore.name].quantity += quantity;

    // Add to lifetime collection
    if (!this.totalCollectedOres[ore.name]) {
      this.totalCollectedOres[ore.name] = {
        ore,
        quantity: 0
      };
    }
    this.totalCollectedOres[ore.name].quantity += quantity;

    // Handle auto-sell if enabled
    if (this.hasAutoSell) {
      this.sellOre(ore.name, quantity);
    }
  }

  sellOre(oreName: string, quantity?: number): number {
    const oreInventory = this.oreInventory[oreName];
    if (!oreInventory) return 0;

    const amountToSell = quantity ?? oreInventory.quantity;
    const actualAmount = Math.min(amountToSell, oreInventory.quantity);
    
    // Apply value multiplier only at point of sale
    const value = oreInventory.ore.value * actualAmount * this.valueMultiplier;
    this.money += value;
    
    oreInventory.quantity -= actualAmount;
    if (oreInventory.quantity <= 0) {
      delete this.oreInventory[oreName];
    }

    return value;
  }

  sellAllOres(): number {
    let totalValue = 0;
    for (const oreName of Object.keys({...this.oreInventory})) {
      totalValue += this.sellOre(oreName);
    }
    return totalValue;
  }

  // Level System
  private getRequiredOresForLevel(level: number): number {
    if (level === 1) return 0;
    const baseRequirement = 1000;
    return Math.floor(baseRequirement * Math.pow(level - 1, 1.5));
  }

  getLevel(): number {
    const totalOres = Object.values(this.totalCollectedOres)
      .reduce((total, { quantity }) => total + quantity, 0);

    if (totalOres === 0) return 1;

    let level = 1;
    while (totalOres >= this.getRequiredOresForLevel(level + 1)) {
      level++;
    }

    return level;
  }

  getLevelProgress(): { current: number; required: number; percentage: number } {
    const totalOres = Object.values(this.totalCollectedOres)
      .reduce((total, { quantity }) => total + quantity, 0);

    const currentLevel = this.getLevel();
    const currentLevelOres = this.getRequiredOresForLevel(currentLevel);
    const nextLevelOres = this.getRequiredOresForLevel(currentLevel + 1);

    if (currentLevel === 1) {
      return {
        current: totalOres,
        required: nextLevelOres,
        percentage: (totalOres / nextLevelOres) * 100
      };
    }

    const oresIntoCurrentLevel = totalOres - currentLevelOres;
    const oresNeededForNextLevel = nextLevelOres - currentLevelOres;

    return {
      current: oresIntoCurrentLevel,
      required: oresNeededForNextLevel,
      percentage: (oresIntoCurrentLevel / oresNeededForNextLevel) * 100
    };
  }

  // Inventory Queries
  getOreQuantity(oreName: string): number {
    return this.oreInventory[oreName]?.quantity || 0;
  }

  getTotalCollectedQuantity(oreName: string): number {
    return this.totalCollectedOres[oreName]?.quantity || 0;
  }

  getInventory(): OreInventory {
    return { ...this.oreInventory };
  }

  getTotalCollection(): OreInventory {
    return { ...this.totalCollectedOres };
  }

  // Worth Calculations
  get netWorth(): number {
    // Calculate raw ore values (without multiplier) + current money
    const oreValue = Object.values(this.oreInventory).reduce((total, { ore, quantity }) => {
      return total + (ore.value * quantity);
    }, 0);
    return oreValue + this.money;
  }

  get potentialValue(): number {
    // Calculate potential value if all ores were sold with current multiplier
    return this.netWorth * this.valueMultiplier;
  }

  // Mining Rates
  get totalMiningSpeed(): number {
    return (this.autoMiners * this.autoMinerSpeed) + (this.quarries * this.quarrySpeed);
  }

  // Inventory Summaries
  getInventorySummary(): Array<{oreName: string; quantity: number; totalValue: number}> {
    return Object.values(this.oreInventory).map(({ ore, quantity }) => ({
      oreName: ore.name,
      quantity,
      totalValue: ore.value * quantity  // Raw value without multiplier
    }));
  }

  getTotalCollectionSummary(): Array<{oreName: string; quantity: number; totalValue: number}> {
    return Object.values(this.totalCollectedOres).map(({ ore, quantity }) => ({
      oreName: ore.name,
      quantity,
      totalValue: ore.value * quantity  // Raw value without multiplier
    }));
  }

  // Utility Methods
  clearInventory(): void {
    this.oreInventory = {};
  }

  setInventoryOre(ore: Ore, quantity: number): void {
    if (quantity > 0) {
      this.oreInventory[ore.name] = { ore, quantity };
    }
  }

  setTotalCollectionOre(ore: Ore, quantity: number): void {
    if (quantity > 0) {
      this.totalCollectedOres[ore.name] = { ore, quantity };
    }
  }

  get name(): string {
    return this.username;
  }
}
